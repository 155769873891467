export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_ERROR = 'GET_CART_ERROR';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR';

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_ERROR = 'REMOVE_FROM_CART_ERROR';

export const REQUEST_QUOTE = 'REQUEST_QUOTE';
export const REQUEST_QUOTE_SUCCESS = 'REQUEST_QUOTE_SUCCESS';
export const REQUEST_QUOTE_ERROR = 'REQUEST_QUOTE_ERROR';

export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDER_SUCCESS';
export const PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER_ERROR';
