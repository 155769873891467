import * as c from './types';

const initialState = {
  data: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case c.GET_CART: {
      return {
        ...state,
        loading: true
      };
    }
    case c.GET_CART_SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false
      };
    }
    case c.GET_CART_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.ADD_TO_CART: {
      return {
        ...state,
        loading: true
      };
    }
    case c.ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false
      };
    }
    case c.ADD_TO_CART_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.REMOVE_FROM_CART: {
      return {
        ...state,
        loading: true
      };
    }
    case c.REMOVE_FROM_CART_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload),
        loading: false
      };
    }
    case c.REMOVE_FROM_CART_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.REQUEST_QUOTE: {
      return {
        ...state,
        loading: true
      };
    }
    case c.REQUEST_QUOTE_SUCCESS: {
      return {
        ...state,
        data: [],
        loading: false
      };
    }
    case c.REQUEST_QUOTE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.PURCHASE_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.PURCHASE_ORDER_SUCCESS: {
      return {
        ...state,
        data: [],
        loading: false
      };
    }
    case c.PURCHASE_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    default:
      return state;
  }
};
