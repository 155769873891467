import { gql } from '@apollo/client';

export const GET_ORDERS_Q = gql`
  query getOrders(
    $status: String
    $team_id: Int
    $query: String
    $first: Int
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    orders(
      status: $status
      team_id: $team_id
      query: $query
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user {
          id
          name
          email
        }
        items {
          id
          product {
            id
            created_at
            description
            title
            cost
            licence_renewal_cost
          }
          price
          qty
        }
        team {
          id
          name
        }
        invoice_url
        status
        created_at
        discount
        total
        topay
        quote_url
        po {
          id
          media_url
          download_url
          media_name
        }
      }
    }
  }
`;

export const GET_ORDER_ACTIVITY_Q = gql`
  query getOrderActivity($id: ID) {
    order(id: $id) {
      activities {
        title
      }
    }
  }
`;

export const GET_ORDER_Q = gql`
  query getOrder($id: ID) {
    order(id: $id) {
      id
      user {
        id
        name
        email
      }
      items {
        id
        product {
          id
          category {
            id
            name
          }
          grade {
            id
            title
          }
          subject {
            id
            title
          }
          description
          title
          cost
          licence_renewal_cost
          media {
            id
            media_url
            media_name
          }
          preview {
            id
            media_url
            media_name
          }
        }
        price
        qty
      }
      status
      created_at
      total
      team {
        id
        name
        pivot {
          team_role
        }
      }
      invoice_url
      quote_url
      po {
        id
        media_url
        download_url
        media_name
      }
      activities {
        id
        title
        created_at
        updated_at
      }
      discount
      total
      topay
    }
  }
`;

export const CREATE_ORDER_M = gql`
  mutation createOrder {
    id
    user {
      id
      name
      email
    }
    items {
      id
      product {
        id
        category {
          id
          name
        }
        grade {
          id
          title
        }
        subject {
          id
          title
        }
        description
        title
        cost
        licence_renewal_cost
        media {
          id
          media_url
          media_name
        }
        preview {
          id
          media_url
          media_name
        }
      }
      price
      qty
    }
    status
    created_at
    total
  }
`;

export const SEND_REMINDER_M = gql`
  mutation sendReminder($id: ID!) {
    reminder(id: $id) {
      status
      message
    }
  }
`;

export const SAVE_ORDER_M = gql`
  mutation saveOrder($team: Int!) {
    order(team: $team) {
      id
      user {
        id
        name
        email
        created_at
        updated_at
      }
      items {
        id
        product {
          id
          subject {
            title
          }
          producttype {
            title
          }
          category {
            name
          }
          grade {
            title
          }
          description
          title
          cost
        }
        price
        qty
      }
      status
      total
      topay
      discount
      team {
        id
        name
        pivot {
          team_role
        }
        users {
          id
          name
          email
          created_at
          updated_at
        }
      }
    }
  }
`;

export const SAVE_ADMIN_ORDER_M = gql`
  mutation saveAdminOrder($input: OrderInput!) {
    adminOrder(input: $input) {
      id
      user {
        id
        name
        email
        created_at
        updated_at
        roles {
          id
          role
        }
        cart_items {
          qty
          user_id
        }
      }
      items {
        id
        product {
          id
          created_at
          subject {
            id
            title
          }
          producttype {
            id
            title
          }
          grade {
            id
            title
          }
          description
          title
          cost
        }
        price
        qty
      }
      status
      team {
        id
        name
        users {
          id
          name
          email
          created_at
          updated_at
        }
      }
    }
  }
`;

export const ADD_DISCOUNT_TO_ORDER_M = gql`
  mutation addDiscountToAdminOrder($input: OrderInput!) {
    adminOrder(input: $input) {
      id
      user {
        id
        name
        email
        created_at
        updated_at
        roles {
          id
          role
        }
        cart_items {
          qty
          user_id
        }
      }
      status
      topay
      discount
      team {
        id
        name
        users {
          id
          name
          email
          created_at
          updated_at
        }
      }
      quote_url
      po {
        id
        media_url
        download_url
        media_name
      }
    }
  }
`;

// export const APPROVE_PO_M = gql`
//   mutation approvePo($input: OrderInput!) {
//     adminOrder(input: $input) {
//       id
//       status
//     }
//   }
// `;
export const APPROVE_PO_M = gql`
  mutation changeOrder($id: ID!, $po_number: String!, $status: String!) {
    changeOrder(id: $id, po_number: $po_number, status: $status) {
      id
      items {
        id
      }
      po_number
      status
    }
  }
`;

export const SAVE_ORDER_ITEM_M = gql`
  mutation saveOrderItem($input: OrderItemInput!) {
    orderItem(input: $input) {
      id
      product {
        id
        subject {
          title
        }
        producttype {
          title
        }
        category {
          name
        }
        grade {
          title
        }
        description
        title
        cost
        licence_renewal_cost
        media {
          media_url
        }
        status
      }
    }
  }
`;

export const DELETE_ORDER_M = gql`
  mutation deleteOrder($id: ID) {
    deleteOrder(id: $id) {
      id
    }
  }
`;

export const CHANGE_ORDER_STATUS_M = gql`
  mutation changeOrder($id: ID!, $status: String!) {
    changeOrder(id: $id, status: $status) {
      id
      items {
        id
      }
      status
    }
  }
`;

export const DELETE_ORDER_ITEM_M = gql`
  mutation deleteOrderItem($id: ID!) {
    deleteorderItem(id: $id) {
      id
      qty
    }
  }
`;

export const GET_MY_ORDERS_Q = gql`
  query getMyOrders {
    myorders {
      id
      user {
        id
        name
        email
      }
      items {
        id
        product {
          id
          category {
            id
            name
          }
          grade {
            id
            title
          }
          subject {
            id
            title
          }
          description
          title
          cost
          licence_renewal_cost
          media {
            id
            media_url
            media_name
          }
          preview {
            id
            media_url
            media_name
          }
        }
        price
        qty
      }
      status
      created_at
      total
      team {
        id
        name
        pivot {
          team_role
        }
      }
      quote_url
      po {
        id
        media_url
        download_url
        media_name
      }
    }
  }
`;

export const REQUEST_STRIPE_M = gql`
  query stripeintent {
    stripeintent {
      client_secret
    }
  }
`;

export const REQUEST_CHARGE_ORDER_M = gql`
  mutation chargeorder($id: String!, $order_id: ID!) {
    chargeorder(id: $id, order_id: $order_id) {
      status
      id
    }
  }
`;

export const REORDER_UNIT_M = gql`
  mutation ReorderUnit($order: [Int]) {
    reorderunit(order: $order) {
      status
    }
  }
`;

export const REORDER_UNIT_ITEM_M = gql`
  mutation ReorderUnitItem($order: [Int]) {
    reorderunititem(order: $order) {
      status
    }
  }
`;
