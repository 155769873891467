export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';

export const GET_ORDER_ACTIVITY = 'GET_ORDER_ACTIVITY';
export const GET_ORDER_ACTIVITY_SUCCESS = 'GET_ORDER_ACTIVITY_SUCCESS';
export const GET_ORDER_ACTIVITY_ERROR = 'GET_ORDER_ACTIVITY_ERROR';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';

export const SEND_REMINDER = 'SEND_REMINDER';
export const SEND_REMINDER_SUCCESS = 'SEND_REMINDER_SUCCESS';
export const SEND_REMINDER_ERROR = 'SEND_REMINDER_ERROR';

export const SAVE_ORDER = 'SAVE_ORDER';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_ERROR = 'SAVE_ORDER_ERROR';

export const SAVE_ADMIN_ORDER = 'SAVE_ADMIN_ORDER';
export const SAVE_ADMIN_ORDER_SUCCESS = 'SAVE_ORDER_ADMIN_SUCCESS';
export const SAVE_ADMIN_ORDER_ERROR = 'SAVE_ADMIN_ORDER_ERROR';

export const SAVE_ORDER_ITEM = 'SAVE_ORDER_ITEM';
export const SAVE_ORDER_ITEM_SUCCESS = 'SAVE_ORDER_ITEM_SUCCESS';
export const SAVE_ORDER_ITEM_ERROR = 'SAVE_ORDER_ITEM_ERROR';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR';

export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM';
export const DELETE_ORDER_ITEM_SUCCESS = 'DELETE_ORDER_ITEM_SUCCESS';
export const DELETE_ORDER_ITEM_ERROR = 'DELETE_ORDER_ITEM_ERROR';

export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_ERROR = 'CHANGE_ORDER_STATUS_ERROR';

export const GET_MY_ORDERS = 'GET_MY_ORDERS';
export const GET_MY_ORDERS_SUCCESS = 'GET_MY_ORDERS_SUCCESS';
export const GET_MY_ORDERS_ERROR = 'GET_MY_ORDERS_ERROR';

export const ADD_DISCOUNT_TO_ORDER = 'ADD_DISCOUNT_TO_ORDER';
export const ADD_DISCOUNT_TO_ORDER_SUCCESS = 'ADD_DISCOUNT_TO_ORDER_SUCCESS';
export const ADD_DISCOUNT_TO_ORDER_ERROR = 'ADD_DISCOUNT_TO_ORDER_ERROR';

export const APPROVE_PO = 'APPROVE_PO';
export const APPROVE_PO_SUCCESS = 'APPROVE_PO_SUCCESS';
export const APPROVE_PO_ERROR = 'APPROVE_PO_ERROR';
