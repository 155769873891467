import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import appReducer from './reducers';
import appSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [sagaMiddleware, thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = require('redux-logger');
  middlewares.push(logger.logger);
}

const store = createStore(appReducer, composeEnhancers(applyMiddleware.apply(null, middlewares)));
sagaMiddleware.run(appSaga);

export default store;
