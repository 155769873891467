import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { useLazyQuery } from '@apollo/client';

import { LS_KEY, CART_KEY } from './api';
import { getUserMe, setAppIsLoading } from './store/auth/actions';
import { getCart } from './store/cart/actions';
import Routes from './routes';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

function AppMiddleware({
  userData,
  currentTeamId,
  dispatchGetUserMe,
  dispatchGetCart,
  dispatchSetAppIsLoading,
  appLoading
}) {
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const checkUserSignedIn = async () => {
    if (window.localStorage.getItem(LS_KEY)) {
      await dispatchGetUserMe(history);
    } else {
      await dispatchSetAppIsLoading(false);
    }

    setIsLoaded(true);
  };

  const checkForCart = async () => {
    await dispatchGetCart(
      window.localStorage.getItem(CART_KEY) ?? userData.id,
      currentTeamId
    );
  };

  useEffect(() => {
    checkForCart();
  }, [currentTeamId]);

  useEffect(() => {
    if (!isLoaded) {
      checkUserSignedIn();
    }

    if (isLoaded) {
      checkForCart();
    }
  }, [userData, isLoaded]);

  return <>{appLoading ? <LoadingScreen /> : <Routes />}</>;
}

const mapStateToProps = (state) => {
  const { data: userData, appLoading, currentTeam } = state.auth;

  return {
    userData,
    appLoading,
    currentTeamId: currentTeam ? currentTeam.id : null
  };
};

const mapDispatchToProps = {
  dispatchGetUserMe: getUserMe,
  dispatchGetCart: getCart,
  dispatchSetAppIsLoading: setAppIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(AppMiddleware);
