import React, { useEffect, useState } from 'react';
import { Nav, Navbar, NavbarBrand, Image, NavDropdown, Form, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { GrCart } from 'react-icons/gr';
import { FiUser } from 'react-icons/fi';
import { useReactiveVar } from '@apollo/client';

import withAuth from '../../hooks/withAuth';
import { logoutUser, changeCurrentTeam, attachInvitedTeam } from '../../store/auth/actions';
import { getCart } from '../../store/cart/actions';
import { cartItemsVar } from '../../cache';

import logo from '../../../../public/images/logo-md.png';
import './Header.scss';

function Header({
  auth,
  isLoggedIn,
  isAdmin,
  dispatchLogoutUser,
  dispatchChangeCurrentTeam,
  dispatchGetCart,
  currentTeam,
  cart,
  teamsArr,
  invitationData,
  dispatchAttachInvitedTeam
}) {
  console.log('🚀 ~ file: Header.js ~ line 18 ~ Header ~ teamsArr', teamsArr);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cartDisplayCondition, setCartDisplayCondition] = useState(false);
  const cartItems = useReactiveVar(cartItemsVar);
  const isDemoMode = process.env.MIX_DEMO_MODE === 'true';
  const isOrdersEnable = process.env.MIX_ENABLE_ORDERS === 'true';
  const bannerVisibility = typeof auth?.data.id !== 'undefined';

  // const { data } = useQuery(IS_LOGGED_IN);
  const history = useHistory();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    menuOpen ? (document.body.style.overflow = '') : (document.body.style.overflow = 'hidden');
  };

  useEffect(() => {
    if (isLoggedIn && teamsArr.length > 0) {
      setCartDisplayCondition(true);
    } else if (isLoggedIn && teamsArr.length === 0) {
      setCartDisplayCondition(false);
    } else if (!isLoggedIn) {
      setCartDisplayCondition(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (invitationData?.data?.team && isLoggedIn) {
      dispatchAttachInvitedTeam(invitationData?.data?.team);
    }
  }, [invitationData?.data?.team]);

  const logoutUser = async () => {
    await dispatchLogoutUser(history);
  };

  const changeCurrentTeam = async (e) => {
    dispatchChangeCurrentTeam(teamsArr.filter((teamItem) => teamItem.id === e.target.value)[0]);
  };

  const filteredCartItems = cart.data.filter((item) => item?.team_id === currentTeam?.id);

  function campusNameLengthCheck(campusName) {
    if (campusName?.length > 30) {
      return `${campusName.substring(0, 30)} ...`;
    }

    return campusName;
  }

  return (
    <>
      <header className={`header`}>
        <Container>
          <Navbar expand="lg" className="header-nav">
            {!isLoggedIn || teamsArr?.length > 0 || isAdmin ? (
              <Link to="/" className="header-logo">
                <NavbarBrand>
                  <Image src={logo} alt="company logo" fluid />
                </NavbarBrand>
              </Link>
            ) : (
              <Link to="/warning" className="header-logo">
                <NavbarBrand>
                  <Image src={logo} alt="company logo" fluid />
                </NavbarBrand>
              </Link>
            )}

            <div className="header-products-nav nav-buttons mx-auto">
              {isLoggedIn && !isAdmin && teamsArr?.length > 0 && (
                <NavLink
                  exact
                  to="/"
                  className="library btn-outline-primary mr-auto"
                  activeClassName="active">
                  Product Library
                </NavLink>
              )}
            </div>
            <div className="header-right-holder">
              <Navbar.Toggle aria-controls="menu" onClick={toggleMenu} />
              <Navbar.Collapse id="menu" className="header-menu">
                <Nav navbarScroll>
                  {isAdmin && <div className="header-text">Lowman Administration</div>}
                  <div className="nav-buttons">
                    {isAdmin && (
                      <>
                        <NavLink exact to="/" className="btn" activeClassName="active">
                          Products
                        </NavLink>
                        <NavLink exact to="/orders" className="btn">
                          Orders
                        </NavLink>
                        <NavLink exact to="/teams" className="btn">
                          Campuses
                        </NavLink>
                      </>
                    )}
                  </div>
                  {teamsArr?.length > 1 && !isAdmin && (
                    <div className="header-team">
                      <Form.Control as="select" value={currentTeam?.id} onChange={changeCurrentTeam}>
                        {teamsArr?.map((team, i) => (
                          <option key={team.id} value={team.id}>
                            {campusNameLengthCheck(team.name)}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  )}
                  {!isLoggedIn && (
                    <>
                      {!isDemoMode && (
                        <Link className="header-nav-link nav-link" to="/register">
                          Create an account
                        </Link>
                      )}
                      <Link className="header-nav-link header-nav-link-login nav-link" to="/login">
                        Login
                      </Link>
                      {!isOrdersEnable && (
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSdL3fvZ5_FJKV28pHRmuQQ-b7ZxndV6zcyc4mPzvAgqPkBY8Q/viewform"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-outline-primary request-btn">
                          Request a Quote
                        </a>
                      )}
                    </>
                  )}
                  {isLoggedIn &&
                    (teamsArr.length > 0 || isAdmin ? (
                      <NavDropdown
                        title={
                          <>
                            <span className="icon icon-user">
                              <FiUser size="20" />
                            </span>
                            <span>My Account</span>
                          </>
                        }>
                        <Link className="nav-link dropdown-item" to="/user-profile">
                          Profile
                        </Link>
                        {!isAdmin && (
                          <Link className="nav-link dropdown-item" to="/team">
                            Campus
                          </Link>
                        )}
                        {!isAdmin && isOrdersEnable && (
                          <Link className="nav-link dropdown-item" to="/orders">
                            Orders
                          </Link>
                        )}
                        <NavDropdown.Item className="nav-link" onClick={logoutUser}>
                          Log out
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      <NavDropdown.Item className="nav-link" onClick={logoutUser}>
                        Log out
                      </NavDropdown.Item>
                    ))}
                  {!isOrdersEnable && isLoggedIn && !isAdmin && (
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdL3fvZ5_FJKV28pHRmuQQ-b7ZxndV6zcyc4mPzvAgqPkBY8Q/viewform"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-primary request-btn">
                      Request a Quote
                    </a>
                  )}
                  {/* Dropdown menu for mobile, made to be always visible */}
                  {isLoggedIn && (
                    <div className="header-account-menu">
                      <Link className="nav-link dropdown-item" to="/user-profile">
                        Profile
                      </Link>
                      <Link className="nav-link dropdown-item" to="/team">
                        Team
                      </Link>
                      <Link className="nav-link dropdown-item" to="/orders">
                        Orders
                      </Link>
                      <NavDropdown.Item className="nav-link" onClick={logoutUser}>
                        Log out
                      </NavDropdown.Item>
                    </div>
                  )}
                  {!isAdmin && cartDisplayCondition && isOrdersEnable && (
                    <Link className="nav-link" to="/cart">
                      <span className="text">View cart</span>
                      <span className="icon icon-cart">
                        <GrCart size="20" />
                        <span className="icon-cart-amount">
                          {currentTeam ? filteredCartItems?.length : cart.data.length}
                        </span>
                      </span>
                    </Link>
                  )}
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const {
    data: { teams: teamsArr },
    currentTeam,
    invitation
  } = state.auth;

  return {
    cart: state.cart,
    teamsArr,
    currentTeam,
    auth,
    invitationData: invitation
  };
};

const mapDispatchToProps = {
  dispatchLogoutUser: logoutUser,
  dispatchGetCart: getCart,
  dispatchChangeCurrentTeam: changeCurrentTeam,
  dispatchAttachInvitedTeam: attachInvitedTeam
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withAuth)(Header);
