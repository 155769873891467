import React from 'react';
import './LoadingScreen.scss';

function LoadingScreen() {
  return (
    <div className="loadingScreen">
      <div className="spinner-border text-info m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingScreen;
