import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { GET_PRODUCT_TYPES_Q } from '../../api/product';
import api from '../../api';
import { GET_PRODUCT_TYPES } from './types';
import { getProductTypesSuccess, getProductTypesError } from './actions';

function* getProductTypes() {
  try {
    const response = yield api.apiClient.query({
      query: GET_PRODUCT_TYPES_Q
    });

    yield put(getProductTypesSuccess(response.data));
  } catch (error) {
    yield put(
      getProductTypesError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetProductTypes() {
  yield takeEvery(GET_PRODUCT_TYPES, getProductTypes);
}

function* productTypesSaga() {
  yield all([fork(watchGetProductTypes)]);
}

export default productTypesSaga;
