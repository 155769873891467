import { InMemoryCache, makeVar, gql } from '@apollo/client';

/**
 * Set initial values when we create cache variables.
 */

const cartItemsInitialValue = [];

export const cartItemsVar = makeVar(cartItemsInitialValue);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cartItems: {
          read() {
            return cartItemsVar();
          }
        }
      }
    }
  }
});
/*
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: !!localStorage.getItem('__jwtToken__')
  }
});
*/
