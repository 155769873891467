import { connect } from 'react-redux';

const mapStateToProps = ({ auth }) => {
  const { roles, id } = auth.data;

  const isAdmin = roles?.map((userRole) => userRole.role).includes('admin');

  return {
    isLoggedIn: id && id > 0,
    isAdmin
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);
