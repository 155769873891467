import * as c from './types';

export const createProduct = (payload, cb) => ({
  type: c.CREATE_PRODUCT,
  payload: { ...payload, cb }
});

export const createProductSuccess = (payload) => ({
  type: c.CREATE_PRODUCT_SUCCESS,
  payload
});

export const createProductError = (error) => ({
  type: c.CREATE_PRODUCT_ERROR,
  payload: error
});

export const editProduct = (payload) => ({
  type: c.EDIT_PRODUCT,
  payload
});

export const editProductSuccess = (payload) => ({
  type: c.EDIT_PRODUCT_SUCCESS,
  payload
});

export const editProductError = (error) => ({
  type: c.EDIT_PRODUCT_ERROR,
  payload: error
});

export const deleteProduct = (payload) => ({
  type: c.DELETE_PRODUCT,
  payload
});

export const deleteProductSuccess = (payload) => ({
  type: c.DELETE_PRODUCT_SUCCESS,
  payload
});

export const deleteProductError = (error) => ({
  type: c.DELETE_PRODUCT_ERROR,
  payload: error
});

export const getProducts = (id) => ({
  type: c.GET_PRODUCTS,
  payload: id
});

export const getProductsSuccess = (product) => ({
  type: c.GET_PRODUCTS_SUCCESS,
  payload: product
});

export const getProductsError = (error) => ({
  type: c.GET_PRODUCTS_ERROR,
  payload: error
});

export const getTeamProducts = (id) => ({
  type: c.GET_TEAM_PRODUCTS,
  payload: id
});

export const getTeamProductsSuccess = (products) => ({
  type: c.GET_TEAM_PRODUCTS_SUCCESS,
  payload: products
});

export const getTeamProductsError = (error) => ({
  type: c.GET_TEAM_PRODUCTS_ERROR,
  payload: error
});

export const getProduct = (id, isEdit = false) => ({
  type: c.GET_PRODUCT,
  payload: {
    id,
    isEdit
  }
});

export const getProductSuccess = (product, isEdit = false) => ({
  type: c.GET_PRODUCT_SUCCESS,
  payload: {
    product,
    isEdit
  }
});

export const getProductError = (error) => ({
  type: c.GET_PRODUCT_ERROR,
  payload: error
});

export const emptyProduct = (product) => ({
  type: c.EMPTY_PRODUCT,
  payload: product
});

export const emptyProductSuccess = (payload) => ({
  type: c.EMPTY_PRODUCT_SUCCESS,
  payload
});

export const emptyProductError = (error) => ({
  type: c.EMPTY_PRODUCT_ERROR,
  payload: error
});

export const updateProductStatus = (payload) => ({
  type: c.UPDATE_PRODUCT_STATUS,
  payload
});

export const updateProductStatusSuccess = (payload) => ({
  type: c.UPDATE_PRODUCT_STATUS_SUCCESS,
  payload
});

export const updateProductStatusError = (error) => ({
  type: c.UPDATE_PRODUCT_STATUS_ERROR,
  payload: error
});

export const savePrevOpenedProduct = (subjectId) => ({
  type: c.SAVE_PREV_OPENED_PRODUCT,
  payload: {
    subjectId
  }
});
