export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_ERROR = 'GET_TEAMS_ERROR';

export const GET_TEAM_DETAILS = 'GET_TEAM_DETAILS';
export const GET_TEAM_DETAILS_SUCCESS = 'GET_TEAM_DETAILS_SUCCESS';
export const GET_TEAM_DETAILS_ERROR = 'GET_TEAM_DETAILS_ERROR';

export const GET_TEAM_NAME_AND_LOCATION = 'GET_TEAM_NAME_AND_LOCATION';
export const GET_TEAM_NAME_AND_LOCATION_SUCCESS = 'GET_TEAM_NAME_AND_LOCATION_SUCCESS';
export const GET_TEAM_NAME_AND_LOCATION_ERROR = 'GET_TEAM_NAME_AND_LOCATION_ERROR';

export const CHANGE_TEAM_USER_ROLE = 'CHANGE_TEAM_USER_ROLE';
export const CHANGE_TEAM_USER_ROLE_SUCCESS = 'CHANGE_TEAM_USER_ROLE_SUCCESS';
export const CHANGE_TEAM_USER_ROLE_ERROR = 'CHANGE_TEAM_USER_ROLE_ERROR';

export const UPDATE_TEAM_DETAILS = 'UPDATE_TEAM_DEATILS';
export const UPDATE_TEAM_DETAILS_SUCCESS = 'UPDATE_TEAM_DEATILS_SUCCESS';
export const UPDATE_TEAM_DETAILS_ERROR = 'UPDATE_TEAM_DEATILS_ERROR';

export const CHANGE_TEAM_NAME = 'CHANGE_TEAM_NAME';
export const CHANGE_TEAM_NAME_SUCCESS = 'CHANGE_TEAM_NAME_SUCCESS';
export const CHANGE_TEAM_NAME_ERROR = 'CHANGE_TEAM_NAME_ERROR';

export const DELETE_USER_FROM_TEAM = 'DELETE_USER_FROM_TEAM';
export const DELETE_USER_FROM_TEAM_SUCCESS = 'DELETE_USER_FROM_TEAM_SUCCESS';
export const DELETE_USER_FROM_TEAM_ERROR = 'DELETE_USER_FROM_TEAM_ERROR';

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';

export const DELETE_INVITE_USER = 'DELETE_INVITE_USER';
export const DELETE_INVITE_USER_SUCCESS = 'DELETE_INVITE_USER_SUCCESS';
export const DELETE_INVITE_USER_ERROR = 'DELETE_INVITE_USER_ERROR';

export const CREATE_NEW_TEAM = 'CREATE_NEW_TEAM';
export const CREATE_NEW_TEAM_SUCCESS = 'CREATE_NEW_TEAM_SUCCESS';
export const CREATE_NEW_TEAM_ERROR = 'CREATE_NEW_TEAM_ERROR';

export const GET_TEAM_INVITATIONS = 'GET_TEAM_INVITATIONS';
export const GET_TEAM_INVITATIONS_SUCCESS = 'GET_TEAM_INVITATIONS_SUCCESS';
export const GET_TEAM_INVITATIONS_ERROR = 'GET_TEAM_INVITATIONS_ERROR';

export const GET_TEAM_JOIN_REQUESTS = 'GET_TEAM_JOIN_REQUESTS';
export const GET_TEAM_JOIN_REQUESTS_SUCCESS = 'GET_TEAM_JOIN_REQUESTS_SUCCESS';
export const GET_TEAM_JOIN_REQUESTS_ERROR = 'GET_TEAM_JOIN_REQUESTS_ERROR';

export const APPROVE_JOIN_REQUEST = 'APPROVE_JOIN_REQUEST';
