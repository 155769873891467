import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api, { LS_KEY, CART_KEY, CURRENT_TEAM_KEY } from '../../api';

import {
  LOGIN_M,
  USER_ME_Q,
  LOGOUT_M,
  REGISTER_M,
  // DELETE_INVITE_USER_M,
  INVITE_USER_M,
  UPDATE_USER_INFO_M,
  UPDATE_USER_PASSWORD_M,
  INVITATION_Q,
  ACCEPT_INVITATION_M,
  DELETE_USER_ACCOUNT_M, REQUEST_TO_JOIN_TEAM_M
} from "../../api/user";

import { ATTACH_CART_TO_USER_M } from '../../api/cart';

// Login Redux States
import {
  AUTH_USER,
  LOGOUT_USER,
  GET_USER_ME,
  REGISTER_USER,
  INVITE_USER,
  CHANGE_CURRENT_USER_INFO,
  UPDATE_USER_PASSWORD,
  FETCH_INVITATION,
  ACCEPT_INVITATION,
  DELETE_USER_ACCOUNT,
  GET_BANNER_MESSAGE,
  SAVE_BANNER_MESSAGE,
  REQUEST_TO_JOIN_TEAM
} from './types';
import {
  authUserSuccess,
  authUserError,
  getUserMeSuccess,
  getUserMeError,
  logoutUserSuccess,
  logoutUserError,
  registerUserSuccess,
  registerUserError,
  inviteUserSuccess,
  inviteUserError,
  changeCurrentTeam,
  changeCurrentUserInfoSuccess,
  changeCurrentUserInfoError,
  updateUserPasswordSuccess,
  updateUserPasswordError,
  setAppIsLoading,
  fetchInvitationSuccess,
  fetchInvitationError,
  acceptInvitationSuccess,
  acceptInvitationError,
  deleteUserAccountSuccess,
  deleteUserAccountError,
  saveBannerMessageSuccess,
  saveBannerMessageError,
  getBannerMessageError,
  getBannerMessageSuccess,
  requestToJoinTeamSuccess,
  requestToJoinTeamError
} from './actions';

function* authUser({ payload: { user, history, cb } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: LOGIN_M,
      variables: {
        input: {
          username: user.email,
          password: user.password
        }
      }
    });
    const successResposne = yield put(authUserSuccess(response.data.login));

    if (response.data.login.user.teams.length > 0) {
      let selectedTeam = response.data.login.user.teams[0];

      if (window.localStorage.getItem(CURRENT_TEAM_KEY)) {
        if (
          response.data.login.user.teams.filter(
            (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
          ).length
        ) {
          selectedTeam = response.data.login.user.teams.filter(
            (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
          )[0];
        }
      }

      yield put(changeCurrentTeam(selectedTeam));

      if (window.localStorage.getItem(CART_KEY)) {
        yield api.mutate(ATTACH_CART_TO_USER_M, {
          userId: response.data.login.user.id,
          tempId: window.localStorage.getItem(CART_KEY),
          ...(selectedTeam.id ? { teamId: Number(selectedTeam.id) } : {})
        });
        window.localStorage.setItem(CART_KEY, selectedTeam.id);
        // window.localStorage.setItem(CART_KEY, response.data.login.user.id);
      }
    }

    if (successResposne.payload.user.teams.length === 0) {
      if (
        successResposne.payload.user.roles.length > 0 &&
        successResposne.payload.user.roles[0].role === 'admin'
      ) {
        return;
      }
      history.push('/warning');
      return;
    }

    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    yield put(
      authUserError([
        {
          category: 'generic',
          reason: error
        }
      ])
    );
    // toastr.error(error.message);
  }
}

export function* watchUserLogin() {
  yield takeEvery(AUTH_USER, authUser);
}

function* impersonateUser({ payload: { user, history, cb } }) {
  console.log('HISTORY: ', history);
  try {
    const response = yield api.apiClient.mutate({
      mutation: IMPERSONATE_M,
      variables: {
        input: {
          id: parseInt(user)
        }
      }
    });
    const impersonate_data = response.data.impersonate;
    const successResposne = yield put(impersonateUserSuccess(impersonate_data));

    if (impersonate_data.user.teams.length > 0) {
      let selectedTeam = impersonate_data.user.teams[0];

      if (window.localStorage.getItem(CURRENT_TEAM_KEY)) {
        if (
          impersonate_data.user.teams.filter(
            (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
          ).length
        ) {
          selectedTeam = impersonate_data.user.teams.filter(
            (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
          )[0];
        }
      }

      yield put(changeCurrentTeam(selectedTeam));

      if (window.localStorage.getItem(CART_KEY)) {
        yield api.mutate(ATTACH_CART_TO_USER_M, {
          userId: impersonate_data.user.id,
          tempId: window.localStorage.getItem(CART_KEY),
          ...(selectedTeam.id ? { teamId: Number(selectedTeam.id) } : {})
        });
        window.localStorage.setItem(CART_KEY, selectedTeam.id);
      }
    }

    if (successResposne.payload.user.teams.length === 0) {
      if (
        successResposne.payload.user.roles.length > 0 &&
        successResposne.payload.user.roles[0].role === 'admin'
      ) {
        return;
      }
      history.push('/warning');
      return;
    }

    if (typeof cb === 'function') {
      cb();
    }

    history.push('/');
  } catch (error) {
    yield put(
      impersonateUserError([
        {
          category: 'generic',
          reason: error
        }
      ])
    );
  }
}
export function* watchImpersonateUser() {
  yield takeEvery(IMPERSONATE_USER, impersonateUser);
}

function* fetchInvitation({ payload: { k, cb } }) {
  try {
    const response = yield api.query(INVITATION_Q, {
      k: k
    });
    console.log('🚀 ~ file: saga.js ~ line 139 ~ function*fetchInvitation ~ response', response);
    if (response.data.invitation == null || response.data.invitation?.time_status === false) {
      throw new Error('Bad invite link');
    } else {
      yield put(fetchInvitationSuccess(response.data.invitation));
    }

    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    yield put(
      fetchInvitationError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchFetchInvitation() {
  yield takeEvery(FETCH_INVITATION, fetchInvitation);
}

function* acceptInvitation({ payload: { k, cb, history } }) {
  try {
    const response = yield api.mutate(ACCEPT_INVITATION_M, {
      input: {
        k: k
      }
    });
    console.log('🚀 ~ file: saga.js ~ line 122 ~ function*acceptInvitation ~ response', response);
    if (response.data.acceptInvitation?.status === 'error') {
      window.location.replace('/broken-link') // not ideal but a blanket fix for all the bad problems occuring
    }
    else {
      toastr.success('You have been successfully added to the team. Please refresh the page.')

      history.push('/')  // not ideal but a blanket fix for all the bad problems occuring
      yield put(acceptInvitationSuccess(response.data.invitation));
    }

    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    yield put(
      acceptInvitationError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchAcceptInvitation() {
  yield takeEvery(ACCEPT_INVITATION, acceptInvitation);
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: LOGOUT_M
    });

    if (response) {
      window.localStorage.removeItem(LS_KEY);
      window.localStorage.removeItem(CART_KEY);
    }

    yield put(logoutUserSuccess());

    history.push('/');
  } catch (error) {
    console.log('🚀 ~ file: saga.js ~ line 182 ~ function*logoutUser ~ error', error);

    if (error.message === 'Not Authenticated') {
      window.localStorage.removeItem(LS_KEY);
      window.localStorage.removeItem(CART_KEY);
      yield put(logoutUserSuccess());
      history.push('/');
    } else {
      yield put(
        logoutUserError([
          {
            category: 'generic',
            reason: error.message
          }
        ])
      );
    }
  }
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* endImpersonation({ payload: { history } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: LOGOUT_M
    });

    if (response) {
      const token = window.localStorage.getItem(BU_KEY);
      window.localStorage.removeItem(IMP_KEY);
      window.localStorage.removeItem(CART_KEY);
      window.localStorage.setItem(LS_KEY, token);
      api.setJwtToken(token);
      window.localStorage.removeItem(BU_KEY);

      yield put(endImpersonationSuccess());
    }

    const res = yield api.apiClient.query({
      query: USER_ME_Q,
      fetchPolicy: 'cache-first'
    });

    if (res.data.me) {
      yield put(getUserMeSuccess(res.data.me));

      if (res.data.me.teams.length > 0) {
        let selectedTeam = res.data.me.teams[0];

        if (window.localStorage.getItem(CURRENT_TEAM_KEY)) {
          if (
            res.data.me.teams.filter(
              (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
            ).length
          ) {
            selectedTeam = res.data.me.teams.filter(
              (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
            )[0];
          }
        }
      }

      yield put(changeCurrentTeam(selectedTeam));
    }

    if (typeof cb === 'function') {
      cb();
    }

    history.push('/');
  } catch (error) {
    console.log('🚀 ~ file: saga.js ~ line 182 ~ function*endImpersonation ~ error', error);

    if (error.message === 'Not Authenticated') {
      window.localStorage.removeItem(LS_KEY);
      window.localStorage.removeItem(IMP_KEY);
      window.localStorage.removeItem(CART_KEY);
      yield put(endImpersonationSuccess());
      history.push('/');
    } else {
      yield put(
        endImpersonationError([
          {
            category: 'generic',
            reason: error.message
          }
        ])
      );
    }
  }
}
export function* watchEndImpersonation() {
  yield takeEvery(END_IMPERSONATION, endImpersonation);
}

function* getUserMe({ payload: { isRefetch = false } }) {
  try {
    yield put(setAppIsLoading(true));
    const response = yield api.apiClient.query({
      query: USER_ME_Q,
      fetchPolicy: isRefetch ? 'no-cache' : 'cache-first'
    });

    if (response.data.me) {
      yield put(getUserMeSuccess(response.data.me));

      if (response.data.me.teams.length > 0 && !isRefetch) {
        let selectedTeam = response.data.me.teams[0];

        if (window.localStorage.getItem(CURRENT_TEAM_KEY)) {
          if (
            response.data.me.teams.filter(
              (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
            ).length
          ) {
            selectedTeam = response.data.me.teams.filter(
              (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
            )[0];
          }
        }

        yield put(changeCurrentTeam(selectedTeam));
      }
    } else {
      if (response) {
        window.localStorage.removeItem(LS_KEY);
        window.localStorage.removeItem(CART_KEY);
      }

      yield put(logoutUserSuccess());
    }

    yield put(setAppIsLoading(false));
  } catch (error) {
    yield put(
      getUserMeError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    // todo: check if we have to redirect to login
    //
  }
}

export function* watchGetUserMe() {
  yield takeEvery(GET_USER_ME, getUserMe);
}

function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: REGISTER_M,
      variables: {
        input: {
          name: user.name,
          email: user.email,
          password: user.password,
          password_confirmation: user.password,
          ...(user.invitation
            ? {
                invitation: user.invitation
              }
            : {
                team: {
                  id: parseInt(user.teamOrCampus.id),
                  name: user.teamOrCampus.name
                }
              })
        }
      }
    });
    yield put(registerUserSuccess(response.data));

    if (!user.invitation) {
      history.push({
        pathname: '/verify',
        state: { user }
      });
    } else {
      const response = yield api.apiClient.mutate({
        mutation: LOGIN_M,
        variables: {
          input: {
            username: user.email,
            password: user.password
          }
        }
      });
      yield put(authUserSuccess(response.data.login));

      if (response.data.login.user.teams.length > 0) {
        let selectedTeam = response.data.login.user.teams[0];

        if (window.localStorage.getItem(CURRENT_TEAM_KEY)) {
          if (
            response.data.login.user.teams.filter(
              (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
            ).length
          ) {
            selectedTeam = response.data.login.user.teams.filter(
              (team) => team.id === window.localStorage.getItem(CURRENT_TEAM_KEY)
            )[0];
          }
        }

        yield put(changeCurrentTeam(selectedTeam));

        if (window.localStorage.getItem(CART_KEY)) {
          yield api.mutate(ATTACH_CART_TO_USER_M, {
            userId: response.data.login.user.id,
            tempId: window.localStorage.getItem(CART_KEY),
            ...(selectedTeam.id ? { teamId: Number(selectedTeam.id) } : {})
          });
          window.localStorage.setItem(CART_KEY, selectedTeam.id);
        }
      }
    }
  } catch (error) {
    yield put(
      registerUserError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error(
      error.graphQLErrors.map((item) => {
        if (Object.values(item.validation).length > 0) {
          console.log('item', item);
          return Object.values(item.validation).map((validation) => {
            if (typeof validation === 'object') {
              console.log('validation', validation);
              // return validation.join('<br/>');
              return 'This email is already associated with an account. Please log in and visit the Team Page to claim another campus.';
            }

            return validation;
          });
        }
      })
    );
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* userUpdate({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: UPDATE_USER_INFO_M,
      variables: {
        input: {
          name: payload
        }
      }
    });
    yield put(changeCurrentUserInfoSuccess(payload));
    toastr.success('Your name has been updated');
  } catch (error) {
    yield put(
      changeCurrentUserInfoError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchUserUpdate() {
  yield takeEvery(CHANGE_CURRENT_USER_INFO, userUpdate);
}

function* updateUserPassword({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: UPDATE_USER_PASSWORD_M,
      variables: {
        input: {
          old_password: payload.old_password,
          password: payload.password,
          password_confirmation: payload.password_confirmation
        }
      }
    });

    yield put(updateUserPasswordSuccess(payload));
    toastr.success('Your password has been updated');
  } catch (error) {
    yield put(
      updateUserPasswordError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error('Validation Exception');
  }
}

export function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword);
}

function* inviteUser({ payload: { user, cb } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: INVITE_USER_M,
      variables: {
        input: {
          email: user.email,
          team_id: user.team_id ? parseInt(user.team_id) : null
        }
      }
    });
    // if (response) {
    //   window.localStorage.setItem(LS_KEY, response.data.login.access_token)
    //   api.setJwtToken(response.data.login.access_token)
    // }
    yield put(inviteUserSuccess(response.data));
    toastr.success('The invitation has been sent');
    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    yield put(
      inviteUserError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchUserInvite() {
  yield takeEvery(INVITE_USER, inviteUser);
}

function* requestToJoin({ payload: { requestFromUserId, requestToTeamId } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: REQUEST_TO_JOIN_TEAM_M,
      variables: {
        input: {
          request_from_user_id: requestFromUserId,
          request_to_team_id: requestToTeamId
        }
      }
    });
    if (!response.data.requestToJoinTeam.message === 'Already requested to join') {
      throw new Error();
    }
    const teamId = response.data.requestToJoinTeam.team_id;
    const { requestToJoinTeam: data } = response.data
    toastr[data.status](data.message)
    // console.log(teamId);
    yield put(requestToJoinTeamSuccess(teamId));
    // toastr.success('Request to Join Campus Successful');
  } catch (error) {
    yield put(requestToJoinTeamError());
    toastr.error('Server Error');
  }
}

export function* watchRequestToJoin() {
  yield takeEvery(REQUEST_TO_JOIN_TEAM, requestToJoin);
}

function* deleteUserAccount({ payload: { cb } }) {
  try {
    const response = yield api.mutate(DELETE_USER_ACCOUNT_M);
    // if (response) {
    //   window.localStorage.setItem(LS_KEY, response.data.login.access_token)
    //   api.setJwtToken(response.data.login.access_token)
    // }
    yield put(deleteUserAccountSuccess(response.data));
    toastr.success('Your account has been deleted');
    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    toastr.error(error.message);
    yield put(
      deleteUserAccountError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchDeleteUserAccount() {
  yield takeEvery(DELETE_USER_ACCOUNT, deleteUserAccount);
}

function* saveBannerMessage({ payload: { message, history } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: SAVE_BANNER_MESSAGE_M,
      variables: {
        input: {
          content: message.content,
          active: message.active,
          text_color: message.text_color,
          bg_color: message.bg_color,
          calendar: message.calendar
        }
      }
    });

    console.log('🚀 ~ file: saga.js ~ line 122 ~ function*saveBannerMessage ~ response', response);

    // if (response.data.acceptInvitation?.status === 'error') {
    //   throw new Error('Bad invite link')
    // }
    // else {
    //   yield put(acceptInvitationSuccess(response.data.invitation));
    //   history.push('/');
    //   toastr.success('You have been successfully added to the team');
    // }

    // if (typeof cb === 'function') {
    //   cb();
    // }

    if (response.data.bannermessage.status === 'Success') {
      yield put(saveBannerMessageSuccess(response.data.bannermessage.message));
      history.push('/banner');
      toastr.success('Banner message successfully saved');
    }
    else {
      throw new Error(response.data.bannermessage?.message ?? 'Something went wrong.');
    }
  }
  catch (error) {
    toastr.error(error.message);
    yield put(
      saveBannerMessageError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}
export function* watchSaveBannerMessage() {
  yield takeEvery(SAVE_BANNER_MESSAGE, saveBannerMessage);
}

function* getBannerMessage() {
  try {
    const response = yield api.query(BANNER_MESSAGE_Q)

    if (response.data.bannermessage == null) {
      throw new Error('Something went wrong with the banner.')
    }
    else {
      yield put(getBannerMessageSuccess(response.data.bannermessage));
    }
  }
  catch (error) {
    toastr.error(error.message)
    yield put(
      getBannerMessageError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    )
  }
}
export function* watchGetBannerMessage() {
  yield takeEvery(GET_BANNER_MESSAGE, getBannerMessage)
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchGetUserMe),
    fork(watchUserRegister),
    fork(watchUserInvite),
    fork(watchRequestToJoin),
    fork(watchUserUpdate),
    fork(watchUpdateUserPassword),
    fork(watchFetchInvitation),
    fork(watchAcceptInvitation),
    fork(watchDeleteUserAccount)
  ]);
}

export default authSaga;
