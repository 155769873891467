import { gql } from '@apollo/client';

export const USER_ALL_DETAILS = gql`
  fragment UserAllDetails on User {
    name
    id
    email
    exam_spark
    roles {
      id
      role
    }
    teams {
      id
      name
      spark
      pivot {
        team_role
      }
    }
  }
`;

export const USERS_Q = gql`
  query getUsers {
    users {
      data {
        name
        email
        id
      }
    }
  }
`;

export const USER_Q = gql`
  ${USER_ALL_DETAILS}
  query getUser($id: ID!) {
    user(id: $id) {
      ...UserAllDetails
    }
  }
`;

export const USER_ME_Q = gql`
  ${USER_ALL_DETAILS}
  query getUserMe {
    me {
      ...UserAllDetails
    }
  }
`;

export const INVITATION_Q = gql`
  query fetchInvitation($k: String) {
    invitation(k: $k) {
      id
      email
      user {
        id
        email
        name
      }
      status
      time_status
      team {
        id
        name
      }
    }
  }
`;

export const REGISTER_M = gql`
  mutation registerUser($input: RegisterInput) {
    register(input: $input) {
      tokens {
        access_token
      }
      status
    }
  }
`;

export const LOGIN_M = gql`
  ${USER_ALL_DETAILS}
  mutation loginUser($input: LoginInput) {
    login(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        ...UserAllDetails
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD_M = gql`
  mutation updateUserPassword($input: UpdatePassword!) {
    updatePassword(input: $input) {
      status
      message
    }
  }
`;

export const LOGOUT_M = gql`
  mutation logoutUser {
    logout {
      status
      message
    }
  }
`;

export const VERIFY_EMAIL_M = gql`
  ${USER_ALL_DETAILS}
  mutation verifyUserEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        ...UserAllDetails
      }
    }
  }
`;

export const UPDATE_USER_INFO_M = gql`
  mutation updateUserInfo($input: UpdateUser!) {
    updateUser(input: $input) {
      name
      email
      teams {
        id
        name
      }
    }
  }
`;

export const INVITE_USER_M = gql`
  mutation inviteUser($input: SendEmailInvitation!) {
    sendInvitation(input: $input) {
      email
      message
    }
  }
`;

export const REQUEST_TO_JOIN_TEAM_M = gql`
  mutation requestToJoinTeam($input: RequestToJoinTeam!) {
    requestToJoinTeam(input: $input) {
      status
      team_id
      message
    }
  }
`;

// export const DELETE_INVITE_USER_M = gql`
//   mutation deleteInviteUser($id: ID!){
//     deleteInvitation(id: $id) {
//       id
//     }
//   }
// `;

export const ACCEPT_INVITATION_M = gql`
  mutation acceptTeamInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD_M = gql`
  mutation resetPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_FORGOTEN_PASSWORD_M = gql`
  mutation updateForgottenPass($input: NewPasswordWithCodeInput) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

export const DELETE_USER_ACCOUNT_M = gql`
  mutation deleteUserAccount {
    deleteMyAccount {
      id
    }
  }
`;
