import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from './components/Header/Header';

function MainLayout({ children, userData }) {
  return (
    <>
      <Header />
      {children}
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  userData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStatetoProps = (state) => {
  const {
    auth: { data }
  } = state;

  return {
    userData: data
  };
};

export default connect(mapStatetoProps)(MainLayout);
