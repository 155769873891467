import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { compose } from 'redux';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import MainLayoutRoute from './MainLayoutRoute';
import withAuth from './hooks/withAuth';

/** Product Create */
const LazyTeams = lazy(() => import('./pages/Teams/Teams'));
const LazyTeamsProfile = lazy(() => import('./pages/TeamsProfile/TeamsProfile'));
const LazyTeamProfile = lazy(() => import('./pages/TeamProfile'));
const LazyProductCreate = lazy(() => import('./pages/ProductCreate/ProductCreate'));
const LazyProductView = lazy(() => import('./pages/ProductView/ProductView'));
const LazyProductEdit = lazy(() => import('./pages/ProductEdit/ProductEdit'));
const LazyUserProfile = lazy(() => import('./pages/UserProfile/UserProfile'));
const LazyProductLibrary = lazy(() => import('./pages/ProductLibrary'));
const LazyOrders = lazy(() => import('./pages/Orders'));
const LazyMyOrders = lazy(() => import('./pages/MyOrders'));
const LazyOrderDetails = lazy(() => import('./pages/OrderDetails'));
const LazyNewOrder = lazy(() => import('./pages/NewOrder'));
const LazyRegister = lazy(() => import('./pages/Register'));
const LazyCart = lazy(() => import('./pages/Cart'));
const LazyReviewOrder = lazy(() => import('./pages/ReviewOrder'));
const LazyLogin = lazy(() => import('./pages/Login'));
const LazyForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const LazyUpdateForgottenPassword = lazy(() => import('./pages/ResetPassword'));
const LazyVerify = lazy(() => import('./pages/Verify'));
const LazyWarning = lazy(() => import('./pages/Warning'));
const LazyInvite = lazy(() => import('./pages/Invite'));
const LazyFinish = lazy(() => import('./pages/Finish'));
const LazyJoinTeam = lazy(() => import('./pages/JoinTeam'));
const LazyCreateTeam = lazy(() => import('./pages/CreateTeam/CreateTeam'));
const LazyBrokenLink = lazy(() => import('./pages/BrokenLink/BrokenLink'));

function Routes({ isAdmin }) {
  return (
    <Router>
      <Switch>
        <MainLayoutRoute
          exact
          path="/"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyProductLibrary {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/create-team"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyCreateTeam {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/register"
          onlyPublic
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyRegister {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/login"
          onlyPublic
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyLogin {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/forgot-password"
          onlyPublic
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyForgotPassword {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/reset-password/"
          onlyPublic
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyUpdateForgottenPassword {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/verify"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyVerify {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/warning"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyWarning {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/invite"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyInvite {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          exact
          path="/finish"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyFinish {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/user-profile"
          allowedRoles={['admin', 'user']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyUserProfile {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/product-view/:id"
          allowedRoles={['admin', 'user']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyProductView {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/product-create/:id?/:step?"
          allowedRoles={['admin']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyProductCreate {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/product-edit/:id"
          allowedRoles={['admin']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyProductEdit {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/teams"
          allowedRoles={['admin']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyTeams {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/teams-profile/:id"
          allowedRoles={['admin']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyTeamsProfile {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/orders"
          allowedRoles={['admin', 'user']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              {isAdmin ? <LazyOrders {...matchprops} /> : <LazyMyOrders {...matchprops} />}
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/team"
          allowedRoles={['admin', 'user']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyTeamProfile {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/order-details/:id"
          allowedRoles={['admin', 'user']}
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyOrderDetails {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/jointoteam"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyJoinTeam {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/cart"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyCart {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/review-order"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyReviewOrder {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/new-order"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyNewOrder {...matchprops} />
            </Suspense>
          )}
        />
        <MainLayoutRoute
          path="/broken-link"
          render={(matchprops) => (
            <Suspense fallback={<LoadingScreen />}>
              <LazyBrokenLink {...matchprops} />
            </Suspense>
          )}
        />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => {
  const { data: userData } = state.auth;

  return { userData };
};

export default compose(connect(mapStateToProps), withAuth)(Routes);
