import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { GET_GRADES_Q } from '../../api/product';
import api from '../../api';
import { GET_GRADES } from './types';
import { getGradesSuccess, getGradesError } from './actions';

function* getAllGrades(accData = [], page = 1) {
  const response = yield api.query(GET_GRADES_Q,
    {
      first: 50,
      page: page,
    },
    {
      fetchPolicy: 'no-cache'
    }
  );

  if (response.data.grades.paginatorInfo.count > 0) {
    accData.push(...response.data.grades.data);
  }

  if (response.data.grades.paginatorInfo.lastPage > page) {
    accData = yield getAllGrades(accData, page + 1);
  }

  return accData;
}

function* getGrades() {
  try {
    const allGrades = yield getAllGrades();
    yield put(getGradesSuccess(allGrades));
  } catch (error) {
    yield put(
      getGradesError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetGrades() {
  yield takeEvery(GET_GRADES, getGrades);
}

function* gradeSaga() {
  yield all([fork(watchGetGrades)]);
}

export default gradeSaga;
