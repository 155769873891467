import * as c from './types';

export const getTeams = (payload) => ({
  type: c.GET_TEAMS,
  payload
});

export const getTeamsSuccess = (payload) => ({
  type: c.GET_TEAMS_SUCCESS,
  payload
});

export const getTeamsError = (error) => ({
  type: c.GET_TEAMS_ERROR,
  payload: error
});

export const getTeamDetails = (payload) => ({
  type: c.GET_TEAM_DETAILS,
  payload
});

export const getTeamDetailsSuccess = (payload) => ({
  type: c.GET_TEAM_DETAILS_SUCCESS,
  payload
});

export const getTeamDetailsError = (error) => ({
  type: c.GET_TEAM_DETAILS_ERROR,
  payload: error
});

export const getTeamNameAndLocation = (payload) => ({
  type: c.GET_TEAM_NAME_AND_LOCATION,
  payload
});

export const getTeamNameAndLocationSuccess = (payload) => ({
  type: c.GET_TEAM_NAME_AND_LOCATION_SUCCESS,
  payload
});

export const getTeamNameAndLocationError = (error) => ({
  type: c.GET_TEAM_NAME_AND_LOCATION_ERROR,
  payload: error
});

export const changeTeamUserRole = (user, team, role, cb) => ({
  type: c.CHANGE_TEAM_USER_ROLE,
  payload: {
    user,
    team,
    role,
    cb
  }
});

export const changeTeamUserRoleSuccess = (payload) => ({
  type: c.CHANGE_TEAM_USER_ROLE_SUCCESS,
  payload
});

export const changeTeamUserRoleError = (error) => ({
  type: c.CHANGE_TEAM_USER_ROLE_ERROR,
  payload: error
});

export const createNewTeam = (payload, currentUser, cb) => ({
  type: c.CREATE_NEW_TEAM,
  payload,
  currentUser,
  cb
});

export const createNewTeamSuccess = (payload, currentUser, cb) => ({
  type: c.CREATE_NEW_TEAM_SUCCESS,
  payload,
  currentUser,
  cb
});

export const createNewTeamError = (error) => ({
  type: c.CREATE_NEW_TEAM_ERROR,
  payload: error
});

export const updateTeamDetails = (payload) => ({
  type: c.UPDATE_TEAM_DETAILS,
  payload
});

export const updateTeamDetailsSuccess = (payload) => ({
  type: c.UPDATE_TEAM_DETAILS_SUCCESS,
  payload
});

export const updateTeamDetailsError = (error) => ({
  type: c.UPDATE_TEAM_DETAILS_ERROR,
  payload: error
});

export const changeTeamName = (payload) => ({
  type: c.CHANGE_TEAM_NAME,
  payload
});

export const changeTeamNameSuccess = (payload) => ({
  type: c.CHANGE_TEAM_NAME_SUCCESS,
  payload
});

export const changeTeamNameError = (error) => ({
  type: c.CHANGE_TEAM_NAME_ERROR,
  payload: error
});

export const deleteUserFromTeam = (user, team) => ({
  type: c.DELETE_USER_FROM_TEAM,
  payload: {
    user,
    team
  }
});

export const deleteUserFromTeamSuccess = (payload) => ({
  type: c.DELETE_USER_FROM_TEAM_SUCCESS,
  payload: {
    user: payload.user,
    team: payload.team
  }
});

export const deleteUserFromTeamError = (error) => ({
  type: c.DELETE_USER_FROM_TEAM_ERROR,
  payload: error
});

export const deleteTeam = (id) => ({
  type: c.DELETE_TEAM,
  payload: {
    id
  }
});

export const deleteTeamSuccess = (id) => ({
  type: c.DELETE_TEAM_SUCCESS,
  payload: {
    id
  }
});

export const deleteTeamError = (error) => ({
  type: c.DELETE_TEAM_ERROR,
  payload: error
});

export const deleteInviteUser = (payload) => ({
  type: c.DELETE_INVITE_USER,
  payload
});

export const deleteInviteUserSuccess = (payload) => ({
  type: c.DELETE_INVITE_USER_SUCCESS,
  payload: payload.deleteInvitation.id
});

export const deleteInviteUserError = (error) => ({
  type: c.DELETE_INVITE_USER_ERROR,
  payload: error
});

export const getTeamInvitations = (teamId) => ({
  type: c.GET_TEAM_INVITATIONS,
  payload: {
    teamId
  }
});

export const getTeamInvitationsSuccess = (invitations) => ({
  type: c.GET_TEAM_INVITATIONS_SUCCESS,
  payload: {
    invitations
  }
});

export const getTeamInvitationsError = (error) => ({
  type: c.GET_TEAM_INVITATIONS_ERROR,
  payload: error
});

export const getTeamJoinRequests = (payload) => ({
  type: c.GET_TEAM_JOIN_REQUESTS,
  payload
});

export const getTeamJoinRequestsSuccess = (joinRequests) => ({
  type: c.GET_TEAM_JOIN_REQUESTS_SUCCESS,
  payload: joinRequests
});

export const getTeamJoinRequestsError = (error) => ({
  type: c.GET_TEAM_JOIN_REQUESTS_ERROR,
  payload: error
});

export const approveJoinRequest = (id) => ({
  type: c.APPROVE_JOIN_REQUEST,
  payload: id
});
