import * as c from './types';

import api, { LS_KEY, CURRENT_TEAM_KEY } from '../../api';
import {ATTACH_INVITED_TEAM} from "./types";

const initialState = {
  data: {},
  loading: false,
  appLoaded: false,
  appLoading: true,
  error: null,
  invitation: {
    loading: false,
    data: {},
    error: null
  },
  acceptInvitation: {
    loading: false,
    data: {},
    error: null
  },
  deleteUserAccount: {
    loading: false,
    data: {},
    error: null
  },
  requestToJoinTeam: {
    existing: [],
    loading: false,
    error: null
  },
  inviteUserEmail: null,
  inviteUserError: null,
  inviteUserLoading: false,
  currentTeam: null,
  changeCurrentTeamError: null,
  changeCurrentTeamLoading: false,
  changeCurrentUserInfoError: null,
  changeCurrentUserInfoLoading: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case c.AUTH_USER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.IMPERSONATE_USER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.AUTH_USER_SUCCESS: {
      if (payload) {
        window.localStorage.setItem(LS_KEY, payload.access_token);
        api.setJwtToken(payload.access_token);
      }

      return {
        ...state,
        error: null,
        loading: false,
        data: payload?.user || {}
      };
    }

    case c.IMPERSONATE_USER_SUCCESS: {
      if (payload) {
        const token = window.localStorage.getItem(LS_KEY)
        window.localStorage.removeItem(LS_KEY)
        window.localStorage.setItem(BU_KEY, token)
        window.localStorage.setItem(IMP_KEY, payload.access_token)
        api.setJwtToken(payload.access_token)
      }

      return {
        ...state,
        error: null,
        loading: false,
        data: payload?.user || {}
      };
    }

    case c.AUTH_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case c.IMPERSONATE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case c.LOGOUT_USER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.END_IMPERSONATION: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        data: {}
      };
    }

    case c.END_IMPERSONATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case c.LOGOUT_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case c.END_IMPERSONATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case c.GET_USER_ME: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_USER_ME_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        data: payload || {}
      };
    }
    case c.GET_USER_ME_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }
    case c.UPDATE_TEAMS_COUNT_SUCCESS: {
      const updatedTeamsCount = state.data.teams.filter((team) => team.id !== payload);

      return {
        ...state,
        error: null,
        loading: false,
        data: {
          ...state.data,
          teams: updatedTeamsCount
        }
      };
    }

    case c.ATTACH_INVITED_TEAM: {
      return {
        ...state,
        error: null,
        loading: false,
        data: {
          ...state.data,
          teams: [
            ...state.data.teams,
            {
              __typename: 'Team',
              id: payload.id,
              name: payload.name,
            }
          ].filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.id === value.id
              ))
          )
        }
      };
    }

    case c.REGISTER_USER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        data: payload || {}
      };
    }
    case c.REGISTER_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }
    case c.INVITE_USER: {
      return {
        ...state,
        inviteUserError: null,
        inviteUserLoading: true
      };
    }
    case c.INVITE_USER_SUCCESS: {
      return {
        ...state,
        inviteUserError: null,
        inviteUserLoading: false,
        inviteUserEmail: payload
      };
    }
    case c.INVITE_USER_ERROR: {
      return {
        ...state,
        inviteUserLoading: false,
        inviteUserError: payload
      };
    }

    case c.REQUEST_TO_JOIN_TEAM: {
      console.log('in reducer....')
      return {
        ...state,
        requestToJoinTeam: {
          ...state.requestToJoinTeam,
          loading: true,
          error: null
        }
      };
    }

    case c.REQUEST_TO_JOIN_TEAM_SUCCESS: {
      return {
        ...state,
        requestToJoinTeam: {
          ...state.requestToJoinTeam,
          error: null,
          loading: false,
          existing: [...state.requestToJoinTeam.existing, payload]
        }
      };
    }

    case c.REQUEST_TO_JOIN_TEAM_ERROR: {
      return {
        ...state,
        requestToJoinTeam: {
          ...state.requestToJoinTeam,
          loading: false,
          error: payload
        }
      };
    }

    case c.UPDATE_TEAM_NAME: {
      // const updatedTeamNameInfo = state.data.teams.filter((team) => team?.id === payload.id);
      return {
        ...state,
        data: {
          ...state.data,
          teams: {
            ...state.data.teams
          }
        }
      };
    }

    case c.CHANGE_CURRENT_TEAM: {
      window.localStorage.setItem(CURRENT_TEAM_KEY, payload.id);

      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          ...payload
        },
        data: {
          ...state.data,
          teams: state.data.teams.map((team) => {
            if (team.id === payload.id) {
              return { ...team, name: payload.name };
            }

            return team;
          })
        }
      };
    }
    case c.SET_APP_LOADING: {
      return {
        ...state,
        appLoading: payload
      };
    }
    case c.SET_APP_LOADED: {
      return {
        ...state,
        appLoaded: payload
      };
    }

    case c.CHANGE_CURRENT_USER_INFO: {
      return {
        ...state,
        changeCurrentUserInfoError: null,
        changeCurrentUserInfoLoading: false
      };
    }

    case c.CHANGE_CURRENT_USER_INFO_SUCCESS: {
      return {
        ...state,
        changeCurrentUserInfoError: null,
        changeCurrentUserInfoLoading: false,
        data: {
          ...state.data,
          name: payload
        }
      };
    }

    case c.CHANGE_CURRENT_USER_INFO_ERROR: {
      return {
        ...state,
        changeCurrentUserInfoLoading: true,
        changeCurrentUserInfoError: payload
      };
    }

    case c.UPDATE_USER_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false
        // data: payload
      };
    }

    case c.UPDATE_USER_PASSWORD_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.FETCH_INVITATION: {
      return {
        ...state,
        invitation: {
          data: state.invitation.data,
          error: null,
          loading: true
        }
      };
    }

    case c.FETCH_INVITATION_SUCCESS: {
      return {
        ...state,
        invitation: {
          data: payload,
          error: null,
          loading: false
        }
      };
    }

    case c.FETCH_INVITATION_ERROR: {
      return {
        ...state,
        invitation: {
          data: state.invitation.data,
          error: payload,
          loading: false
        }
      };
    }

    case c.ACCEPT_INVITATION: {
      return {
        ...state,
        acceptInvitation: {
          data: state.acceptInvitation.data,
          error: null,
          loading: true
        }
      };
    }

    case c.ACCEPT_INVITATION_SUCCESS: {
      return {
        ...state,
        acceptInvitation: {
          data: payload,
          error: null,
          loading: false
        }
      };
    }

    case c.ACCEPT_INVITATION_ERROR: {
      return {
        ...state,
        acceptInvitation: {
          data: state.invitation.status,
          error: payload,
          loading: false
        }
      };
    }

    case c.DELETE_USER_ACCOUNT: {
      return {
        ...state,
        deleteUserAccount: {
          loading: true
        }
      };
    }

    case c.DELETE_USER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deleteUserAccount: {
          loading: false
        }
      };
    }

    case c.DELETE_USER_ACCOUNT_ERROR: {
      return {
        ...state,
        deleteUserAccount: {
          loading: false,
          error: payload
        }
      };
    }

    case c.ATTACH_USER_TO_TEAM: {
      return {
        ...state,
        loadingChangeTeamRole: true
      };
    }

    case c.ATTACH_USER_TO_TEAM_SUCCESS: {
      return {
        ...state,
        loadingChangeTeamRole: false,
        data: {
          ...state.data,
          teams: [
            ...state.data.teams,
            {
              __typename: 'Team',
              id: payload.team,
              name: payload.cb,
              pivot: {
                __typename: 'TeamPivot',
                team_role: payload.role
              }
            }
          ]
        }
      };
    }

    case c.ATTACH_USER_TO_TEAM_ERROR: {
      return {
        ...state,
        loadingChangeTeamRole: false,
        error: payload
      };
    }

    case c.CHANGE_CURRENT_TEAM_TITLE: {
      return {
        ...state,
        data: {
          ...state.data,
          teams: [
            ...state.data.teams.filter((team) => team.id !== payload.id),
            {
              ...state.currentTeam,
              pivot: {
                __typename: state.currentTeam.pivot.__typename,
                team_role: state.currentTeam.pivot.team_role,
                title: payload.title
              }
            }
          ]
        },
        currentTeam: {
          ...state.currentTeam,
          pivot: {
            __typename: state.currentTeam.pivot.__typename,
            team_role: state.currentTeam.pivot.team_role,
            title: payload.title
          }
        }
      };
    }

    case c.CHANGE_CURRENT_TEAM_ROLE: {
      return {
        ...state,
        data: {
          ...state.data,
          teams: [
            ...state.data.teams.filter((team) => team.id !== payload.id),
            {
              ...state.currentTeam,
              pivot: {
                ...state.currentTeam?.pivot,
                team_role: payload?.role
              }
            }
          ]
        },
        currentTeam: {
          ...state.currentTeam,
          pivot: {
            ...state.currentTeam?.pivot,
            team_role: payload?.role
          }
        }
      };
    }

    case c.SAVE_BANNER_MESSAGE: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.SAVE_BANNER_MESSAGE_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        data: payload || {}
      };
    }
    case c.SAVE_BANNER_MESSAGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case c.GET_BANNER_MESSAGE: {
      return {
        ...state,
        bannerMessage: {
          loading: true,
          data: payload || {}
        }
      };
    }
    case c.GET_BANNER_MESSAGE_SUCCESS: {
      return {
        ...state,
        bannerMessage: {
          loading: false,
          data: payload || {}
        }
      };
    }
    case c.GET_BANNER_MESSAGE_ERROR: {
      return {
        ...state,
        bannerMessage: {
          loading: false,
          error: payload
        }
      };
    }
  }

  return state;
};
