import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { GET_SUBJECTS_Q } from '../../api/product';
import api from '../../api';
import { GET_SUBJECTS } from './types';
import { getSubjectsSuccess, getSubjectsError } from './actions';

function* getAllSubjects(accData = [], page = 1) {
  const response = yield api.query(GET_SUBJECTS_Q,
    {
      first: 50,
      page: page,
    },
    {
      fetchPolicy: 'no-cache'
    }
  );

  if (response.data.subjects.paginatorInfo.count > 0) {
    accData.push(...response.data.subjects.data);
  }

  if (response.data.subjects.paginatorInfo.lastPage > page) {
    accData = yield getAllSubjects(accData, page + 1);
  }

  return accData;
}

function* getSubjects() {
  try {
    const allSubjects = yield getAllSubjects();
    yield put(getSubjectsSuccess(allSubjects));
  } catch (error) {
    yield put(
      getSubjectsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetSubjects() {
  yield takeEvery(GET_SUBJECTS, getSubjects);
}

function* subjectSaga() {
  yield all([fork(watchGetSubjects)]);
}

export default subjectSaga;
