import { gql } from '@apollo/client';

export const GET_CART_Q = gql`
  query getCartItems($userId: ID, $teamId: ID) {
    cartitems(user_id: $userId, team_id: $teamId) {
      id
      product {
        id
        created_at
        subject {
          id
          title
        }
        producttype {
          id
          title
        }
        grade {
          id
          title
        }
        description
        title
        cost
        licence_renewal_cost
        media {
          id
          media_url
          download_url
          media_name
        }
        preview {
          id
          media_url
          download_url
          media_name
        }
      }
      qty
      user_id
      team_id
    }
  }
`;

export const ADD_TO_CART_M = gql`
  mutation addToCart($userId: ID, $productId: ID!, $qty: Int!, $teamId: Int) {
    addtocart(user_id: $userId, product_id: $productId, qty: $qty, team_id: $teamId) {
      id
      product {
        id
        created_at
        subject {
          id
          title
        }
        producttype {
          id
          title
        }
        grade {
          id
          title
        }
        description
        title
        cost
        licence_renewal_cost
        media {
          id
          media_url
          download_url
          media_name
        }
        preview {
          id
          media_url
          download_url
          media_name
        }
      }
      qty
      user_id
      team_id
    }
  }
`;

export const REMOVE_FROM_CART_M = gql`
  mutation removeFromCart($id: ID) {
    removecartitem(id: $id) {
      status
    }
  }
`;

export const ATTACH_CART_TO_USER_M = gql`
  mutation cartToUser($userId: ID!, $tempId: String!, $teamId: Int) {
    carttouser(user_id: $userId, temp_id: $tempId, team_id: $teamId) {
      id
      product {
        id
        created_at
        subject {
          id
          title
        }
        producttype {
          id
          title
        }
        grade {
          id
          title
        }
        description
        title
        cost
      }
      qty
      user_id
      team_id
    }
  }
`;

export const REQUEST_QUOTE_M = gql`
  mutation requestQuote($team: Int!, $discount: Int) {
    requestQuote(team: $team, discount: $discount) {
      id
      user {
        id
      }
      discount
      items {
        id
        qty
      }
    }
  }
`;
