import * as c from './types';

const initialState = {
  data: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case c.GET_SUBJECTS: {
      return {
        ...state,
        data: payload,
        loading: true
      };
    }
    case c.GET_SUBJECTS_SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false
      };
    }
    case c.GET_SUBJECTS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.ADD_NEW_SUBJECT: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: payload.id,
            title: payload.title
          }
        ],
        loading: false
      };
    }
    default:
      return state;
  }
};
