import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import auth from './auth/reducer';
import product from './product/reducer';
import grade from './grade/reducer';
import subject from './subject/reducer';
import productType from './productType/reducer';
import teams from './teams/reducer';
import cart from './cart/reducer';
import orders from './orders/reducer';

const appReducer = combineReducers({
  auth,
  product,
  grade,
  subject,
  productType,
  teams,
  cart,
  orders,
  toastr: toastrReducer
});

export default appReducer;
