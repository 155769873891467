import * as c from './types';

const productManagementInitialState = {
  data: {},
  loading: false,
  error: null,
  isSuccess: false
};

const initialState = {
  data: {},
  dataLoading: false,
  product: {},
  list: [],
  team_list: [],
  error: null,
  loading: false,
  team_products: [],
  last_opened_product: null,
  product_management: productManagementInitialState
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case c.CREATE_PRODUCT: {
      return {
        ...state,
        product_management: {
          ...productManagementInitialState,
          data: payload,
          loading: true
        }
      };
    }
    case c.CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        data: payload,
        product_management: {
          ...productManagementInitialState,
          data: payload,
          isSuccess: true,
          list: []
        }
      };
    }
    case c.CREATE_PRODUCT_ERROR: {
      return {
        ...state,
        product_management: {
          ...productManagementInitialState,
          data: state.product_management.data,
          error: payload
        }
      };
    }
    case c.EDIT_PRODUCT: {
      return {
        ...state,
        data: payload,
        error: null,
        loading: true
      };
    }
    case c.EDIT_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...payload },
        product_management: {
          ...productManagementInitialState,
          data: payload,
          error: null
        },
        list: []
      };
    }
    case c.EDIT_PRODUCT_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.DELETE_PRODUCT: {
      return {
        ...state,
        product: payload,
        error: null,
        loading: true
      };
    }
    case c.DELETE_PRODUCT_SUCCESS: {
      const filteredProducts = state.list?.filter((product) => product?.id === payload);

      return {
        ...state,
        loading: false,
        error: null,
        product: payload,
        list: [...filteredProducts]
      };
    }
    case c.DELETE_PRODUCT_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.GET_PRODUCTS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        list: payload,
        loading: false
      };
    }
    case c.GET_PRODUCTS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.GET_TEAM_PRODUCTS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_TEAM_PRODUCTS_SUCCESS: {
      return {
        ...state,
        team_products: payload,
        loading: false
      };
    }
    case c.GET_TEAM_PRODUCTS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.GET_PRODUCT: {
      return {
        ...state,
        error: null,
        dataLoading: true
      };
    }
    case c.GET_PRODUCT_SUCCESS: {
      return {
        ...state,
        data: payload.product,
        ...(payload.isEdit
          ? {
              product_management: {
                ...productManagementInitialState,
                data: payload.product,
                error: null
              }
            }
          : {}),
        dataLoading: false
      };
    }
    case c.GET_PRODUCT_ERROR: {
      return {
        ...state,
        error: payload,
        dataLoading: false
      };
    }
    case c.EMPTY_PRODUCT: {
      return {
        ...state,
        product: {},
        data: {}
      };
    }
    case c.UPDATE_PRODUCT_STATUS: {
      return {
        ...state,
        product_management: {
          ...productManagementInitialState,
          data: payload,
          loading: true
        }
      };
    }
    case c.UPDATE_PRODUCT_STATUS_SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
        product_management: {
          ...productManagementInitialState,
          data: payload,
          isSuccess: true,
          list: []
        }
      };
    }
    case c.UPDATE_PRODUCT_STATUS_ERROR: {
      return {
        ...state,
        product_management: {
          ...productManagementInitialState,
          data: state.product_management.data,
          error: payload
        }
      };
    }
    case c.SAVE_PREV_OPENED_PRODUCT: {
      return {
        ...state,
        last_opened_product: parseInt(payload?.subjectId)
      };
    }
    default:
      return state;
  }
};
