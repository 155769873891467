import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  CREATE,
  EDIT,
  DELETE_PRODUCT_M,
  GET_PRODUCT_Q,
  GET_ALL_PRODUCTS,
  GET_TEAM_PRODUCTS_Q,
  UPDATE_PRODUCT_STATUS_M, GET_GRADES_Q
} from '../../api/product';

import api from '../../api';

import {
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_TEAM_PRODUCTS,
  UPDATE_PRODUCT_STATUS
} from './types';
import {
  createProductSuccess,
  createProductError,
  editProductSuccess,
  editProductError,
  deleteProductSuccess,
  deleteProductError,
  getProductSuccess,
  getProductError,
  getProductsSuccess,
  getProductsError,
  getTeamProductsSuccess,
  getTeamProductsError,
  updateProductStatusSuccess,
  updateProductStatusError
} from './actions';
import { addNewGrade, addNewSubject } from '../actions';

function* createProduct({ payload, payload: { cb } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: CREATE,
      variables: {
        input: payload
      }
    });
    yield put(createProductSuccess(response.data.product));

    if (typeof cb === 'function') {
      cb(response.data.product.id);
    }

    return response.data;
  } catch (error) {
    yield put(
      createProductError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchProductCreate() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}

function* editProduct({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: EDIT,
      variables: {
        input: payload
      }
    });

    yield put(editProductSuccess(payload));
    toastr.success('The product has been updated successfully');
  } catch (error) {
    yield put(
      editProductError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error('The product has not been updated');
  }
}

export function* watchProductEdit() {
  yield takeEvery(EDIT_PRODUCT, editProduct);
}

function* deleteProduct({ payload }) {
  try {
    const response = api.apiClient.mutate({
      mutation: DELETE_PRODUCT_M,
      variables: {
        id: payload
      }
    });

    yield put(deleteProductSuccess(response));
    toastr.success('The product has been deleted successfully');
  } catch (error) {
    yield put(
      deleteProductError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error('The product has not been deleted');
  }
}

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

function* getProduct({ payload: { id, isEdit } }) {
  try {
    const response = yield api.apiClient.query({
      query: GET_PRODUCT_Q,
      fetchPolicy: 'no-cache',
      variables: {
        id
      }
    });

    yield put(getProductSuccess(response.data.product, isEdit));
  } catch (error) {
    yield put(
      getProductError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

function* getAllProducts(accData = [], page = 1) {
  const response = yield api.query(GET_ALL_PRODUCTS,
    {
      first: 50,
      page: page,
    },
    {
      fetchPolicy: 'no-cache'
    }
  );

  if (response.data.products.paginatorInfo.count > 0) {
    accData.push(...response.data.products.data);
  }

  if (response.data.products.paginatorInfo.lastPage > page) {
    accData = yield getAllProducts(accData, page + 1);
  }

  return accData;
}

function* getProducts() {
  try {
    const allProducts = yield getAllProducts();
    yield put(getProductsSuccess(allProducts));
  } catch (error) {
    yield put(
      getProductsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetProducts() {
  yield takeEvery(GET_PRODUCTS, getProducts);
}

function* getTeamProducts({ payload }) {
  try {
    const response = yield api.query(
      GET_TEAM_PRODUCTS_Q,
      {
        id: payload
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
    yield put(getTeamProductsSuccess(response.data.teamproducts));
  } catch (error) {
    yield put(
      getTeamProductsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetTeamProducts() {
  yield takeEvery(GET_TEAM_PRODUCTS, getTeamProducts);
}

function* updateProductStatus({ payload }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: UPDATE_PRODUCT_STATUS_M,
      variables: {
        id: payload,
        status: true
      }
    });

    const responseAllProducts = yield api.query(
      GET_ALL_PRODUCTS,
      {
        first: 200
      },
      {
        fetchPolicy: 'no-cache'
      }
    );

    const responseGetGrades = yield api.apiClient.query(
      {
        query: GET_GRADES_Q
      },
      {
        fetchPolicy: 'no-cache'
      }
    );

    yield put(updateProductStatusSuccess(response.data.productStatus));
    toastr.success('The product has been created successfully');

    const gradesIdArr = responseGetGrades.data.grades.data.reduce((acc, item) => {
      return [...acc, item.id]
    }, []);

    if(gradesIdArr.includes(response.data.productStatus.grade.id)) {
    } else {
      yield put(
        addNewSubject(
          response.data.productStatus.subject.title,
          response.data.productStatus.subject.id
        )
      );
      yield put(
        addNewGrade(response.data.productStatus.grade.title, response.data.productStatus.grade.id)
      );
    }

    return response.data;
  } catch (error) {
    yield put(
      updateProductStatusError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchUpdateProductStatus() {
  yield takeEvery(UPDATE_PRODUCT_STATUS, updateProductStatus);
}

function* productSaga() {
  yield all([
    fork(watchProductCreate),
    fork(watchProductEdit),
    fork(watchDeleteProduct),
    fork(watchGetProduct),
    fork(watchGetProducts),
    fork(watchGetTeamProducts),
    fork(watchUpdateProductStatus)
  ]);
}

export default productSaga;
