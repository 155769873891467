import * as c from './types';

export const getOrders = (payload) => ({
  type: c.GET_ORDERS,
  payload: payload || {}
});

export const getOrdersSuccess = (payload, pagination, isTeam = null) => ({
  type: c.GET_ORDERS_SUCCESS,
  payload: {
    data: payload,
    isTeam,
    paginatorInfo: pagination
  }
});

export const getOrdersError = (error) => ({
  type: c.GET_ORDERS_ERROR,
  payload: error
});

export const getOrder = (payload) => ({
  type: c.GET_ORDER,
  payload
});

export const getOrderSuccess = (payload) => ({
  type: c.GET_ORDER_SUCCESS,
  payload
});

export const getOrderError = (error) => ({
  type: c.GET_ORDER_ERROR,
  payload: error
});

export const getOrderActivity = (payload) => ({
  type: c.GET_ORDER_ACTIVITY,
  payload
});

export const getOrderActivitySuccess = (payload) => ({
  type: c.GET_ORDER_ACTIVITY_SUCCESS,
  payload
});

export const getOrderActivityError = (error) => ({
  type: c.GET_ORDER_ACTIVITY_ERROR,
  payload: error
});

export const sendReminder = (payload) => ({
  type: c.SEND_REMINDER,
  payload
});

export const sendReminderSuccess = (payload) => ({
  type: c.SEND_REMINDER_SUCCESS,
  payload
});

export const sendReminderError = (error) => ({
  type: c.SEND_REMINDER_ERROR,
  payload: error
});

export const createOrder = (payload) => ({
  type: c.CREATE_ORDER,
  payload
});

export const createOrderSuccess = (payload) => ({
  type: c.CREATE_ORDER_SUCCESS,
  payload
});

export const createOrderError = (error) => ({
  type: c.CHANGE_ORDER_STATUS_ERROR,
  payload: error
});

export const changeOrderStatus = (id, status) => ({
  type: c.CHANGE_ORDER_STATUS,
  payload: {
    id,
    status
  }
});

export const changeOrderStatusSuccess = (id, status) => ({
  type: c.CHANGE_ORDER_STATUS_SUCCESS,
  payload: {
    id,
    status
  }
});

export const changeOrderStatusError = (error) => ({
  type: c.CHANGE_ORDER_STATUS_ERROR,
  payload: error
});

export const saveOrder = (payload) => ({
  type: c.SAVE_ORDER,
  payload
});

export const saveOrderSuccess = (payload) => ({
  type: c.SAVE_ORDER_SUCCESS,
  payload
});

export const saveOrderError = (error) => ({
  type: c.SAVE_ORDER_ERROR,
  payload: error
});

export const saveAdminOrder = (payload, cb) => ({
  type: c.SAVE_ADMIN_ORDER,
  payload: { ...payload, cb }
});

export const saveAdminOrderSuccess = (payload) => ({
  type: c.SAVE_ADMIN_ORDER_SUCCESS,
  payload
});

export const saveAdminOrderError = (error) => ({
  type: c.SAVE_ADMIN_ORDER_ERROR,
  payload: error
});

export const addDiscountToOrder = (payload) => ({
  type: c.ADD_DISCOUNT_TO_ORDER,
  payload
});

export const addDiscountToOrderSuccess = (payload) => ({
  type: c.ADD_DISCOUNT_TO_ORDER_SUCCESS,
  payload
});

export const addDiscountToOrderError = (error) => ({
  type: c.ADD_DISCOUNT_TO_ORDER_ERROR,
  payload: error
});

export const approvePo = (payload) => ({
  type: c.APPROVE_PO,
  payload
});

export const approvePoSuccess = (payload) => ({
  type: c.APPROVE_PO_SUCCESS,
  payload
});

export const approvePoError = (error) => ({
  type: c.APPROVE_PO_ERROR,
  payload: error
});

export const saveOrderItem = (payload, cb) => ({
  type: c.SAVE_ORDER_ITEM,
  payload: { payload, cb }
});

export const saveOrderItemSuccess = (payload) => ({
  type: c.SAVE_ORDER_ITEM_SUCCESS,
  payload
});

export const saveOrderItemError = (error) => ({
  type: c.SAVE_ORDER_ITEM_ERROR,
  payload: error
});

export const deleteOrder = (id) => ({
  type: c.DELETE_ORDER,
  payload: {
    id
  }
});

export const deleteOrderSuccess = (payload) => ({
  type: c.DELETE_ORDER_SUCCESS,
  payload
});

export const deleteOrderError = (error) => ({
  type: c.DELETE_ORDER_ERROR,
  payload: error
});

export const deleteOrderItem = (ids, cb) => ({
  type: c.DELETE_ORDER_ITEM,
  payload: { ids, cb }
});

export const deleteOrderItemSuccess = (payload) => ({
  type: c.DELETE_ORDER_ITEM_SUCCESS,
  payload
});

export const deleteOrderItemError = (error) => ({
  type: c.DELETE_ORDER_ITEM_ERROR,
  payload: error
});

export const getMyOrders = (payload) => ({
  type: c.GET_MY_ORDERS,
  payload
});

export const getMyOrdersSuccess = (payload) => ({
  type: c.GET_MY_ORDERS_SUCCESS,
  payload
});

export const getMyOrdersError = (error) => ({
  type: c.GET_MY_ORDERS_ERROR,
  payload: error
});
