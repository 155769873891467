import { gql } from '@apollo/client';
import { UNIT_ALL_DETAILS, UNIT_ITEM_ALL_DETAILS } from './unit';

export const MEDIACOLLECTION_DETAILS = gql`
  fragment MediaCollectionDetails on MediaCollections {
    id
    name
    media {
      id
      media_url
      download_url
      media_name
      mime_type
    }
  }
`;
export const PRODUCT_ALL_DETAILS = gql`
  ${MEDIACOLLECTION_DETAILS}
  fragment ProductAllDetails on Product {
    id
    title
    grade {
      id
      title
    }
    subject {
      id
      title
    }
    producttype {
      id
      title
    }
    unit {
      id
      title
      media {
        id
        media_url
        download_url
        media_name
      }
      unititem {
        id
        title
        media {
          id
          media_url
          download_url
          media_name
          mime_type
        }
        mediacollections {
          ...MediaCollectionDetails
        }
      }
      mediacollections {
        ...MediaCollectionDetails
      }
    }
    description
    cost
    licence_renewal_cost
    media {
      id
      media_url
      download_url
      media_name
      mime_type
    }
    preview {
      id
      media_url
      download_url
      media_name
    }
    mediacollections {
      ...MediaCollectionDetails
    }
  }
`;

const CREATE = gql`
  mutation createProduct($input: ProductInput!) {
    product(input: $input) {
      id
      subject {
        title
      }
      producttype {
        title
      }
      grade {
        title
      }
      description
      title
      cost
      licence_renewal_cost
    }
  }
`;

const DELETE_PRODUCT_M = gql`
  mutation deleteProduct($id: ID!) {
    deleteproduct(id: $id) {
      id
      created_at
      subject {
        id
        title
      }
      producttype {
        id
        title
      }
      grade {
        id
        title
      }
      description
      title
    }
  }
`;

const UPDATE_PRODUCT_STATUS_M = gql`
  ${PRODUCT_ALL_DETAILS}
  mutation updateProductStatus($id: ID!, $status: Boolean!) {
    productStatus(id: $id, status: $status) {
      ...ProductAllDetails
    }
  }
`;

// const EDIT = gql`
//   ${PRODUCT_ALL_DETAILS}
//   mutation editProduct($input: ProductInput!){
//     product(input: $input) {
//       ...ProductAllDetails
//     }
//   }
// `;

const EDIT = gql`
  mutation editProduct($input: ProductInput!) {
    product(input: $input) {
      id
      subject {
        id
        title
      }
      producttype {
        id
        title
      }
      grade {
        id
        title
      }
      description
      title
      cost
      licence_renewal_cost
      status
    }
  }
`;

const GET_GRADES_Q = gql`
  query getGrades($first: Int, $page: Int) {
    grades(first: $first, page: $page) {
      paginatorInfo {
        count
        lastPage
      }
      data {
        id
        title
      }
    }
  }
`;

const GET_SUBJECTS_Q = gql`
  query getSubjects($first: Int, $page: Int) {
    subjects(first: $first, page: $page) {
      paginatorInfo {
          count
          lastPage
        }
      data {
        id
        title
      }
    }
  }
`;

const GET_ALL_PRODUCTS = gql`
  query getAllProducts($first: Int, $page: Int) {
    products(first: $first, page: $page) {
      paginatorInfo {
          count
          lastPage
        }
      data {
        id
        subject {
          id
          title
        }
        category {
          id
          name
        }
        grade {
          id
          title
        }
        producttype {
          id
          title
        }
        title
        unit {
          title
        }
        cost
        status
      }
    }
  }
`;

const GET_TEAM_PRODUCTS_Q = gql`
  query getTeamProducts($id: ID) {
    teamproducts(id: $id) {
      id
      expiring
      created_at
      subject {
        id
        title
      }
      producttype {
        id
        title
      }
      category {
        id
        name
      }
      grade {
        id
        title
      }
      title
      unit {
        title
      }
    }
  }
`;

const GET_PRODUCT_Q = gql`
  ${PRODUCT_ALL_DETAILS}
  ${UNIT_ALL_DETAILS}
  ${UNIT_ITEM_ALL_DETAILS}
  query getProduct($id: ID) {
    product(id: $id) {
      ...ProductAllDetails
      unit {
        ...UnitDetails
        unititem {
          ...UnitItemDetails
        }
      }
      status
    }
  }
`;

// const GET_PRODUCT_UNIT_AND_MEDIA_Q = gql`
//   ${PRODUCT_ALL_DETAILS}
//   ${UNIT_ALL_DETAILS}
//   ${UNIT_ITEM_ALL_DETAILS}
//   query getProduct($id: ID) {
//     product(id: $id) {
//       ...ProductAllDetails
//       unit {
//         ...UnitDetails
//         unititem {
//           ...UnitItemDetails
//         }
//       }
//       media {
//         id
//         media_url
//         download_url
//         media_name
//       }
//     }
//   }
// `;

const GET_PRODUCT_TYPES_Q = gql`
  query getProductTypes {
    producttypes {
      data {
        id
        title
      }
    }
  }
`;

export {
  CREATE,
  EDIT,
  DELETE_PRODUCT_M,
  GET_GRADES_Q,
  GET_SUBJECTS_Q,
  GET_ALL_PRODUCTS,
  GET_TEAM_PRODUCTS_Q,
  GET_PRODUCT_Q,
  GET_PRODUCT_TYPES_Q,
  UPDATE_PRODUCT_STATUS_M
};
