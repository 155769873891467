import ApiClient from './client';

const api = ApiClient.create();

if (typeof window !== 'undefined') {
  const token = window.localStorage.getItem(ApiClient.LS_KEY);

  if (token) {
    api.setJwtToken(token);
  }
}

export const { LS_KEY } = ApiClient;
export const { CART_KEY } = ApiClient;
export const CURRENT_TEAM_KEY = '__currentTeamKey__';

export default api;


