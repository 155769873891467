import * as c from './types';

const initialState = {
  data: [],
  invitations: [],
  details: {},
  error: null,
  loading: false,
  joinRequests: {
    existing: [],
    loading: false,
    error: null
  },
  deleteInviteUserError: null,
  deleteInviteUserLoading: false,
  loadingChangeTeamRole: false,
  loadingDeleteUserFromTeam: false,
  loadingDeleteTeam: false,
  loadingInvitations: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case c.GET_TEAMS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_TEAMS_SUCCESS: {
      return {
        ...state,
        data: payload.teams,
        loading: false
      };
    }
    case c.GET_TEAMS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.GET_TEAM_INVITATIONS: {
      return {
        ...state,
        error: null,
        loadingInvitations: true
      };
    }
    case c.GET_TEAM_INVITATIONS_SUCCESS: {
      return {
        ...state,
        invitations: payload.invitations,
        loadingInvitations: false
      };
    }
    case c.GET_TEAM_INVITATIONS_ERROR: {
      return {
        ...state,
        error: payload,
        loadingInvitations: false
      };
    }

    case c.GET_TEAM_JOIN_REQUESTS: {
      return {
        ...state,
        joinRequests: {
          ...state.joinRequests,
          loading: true,
          error: null
        }
      };
    }

    case c.GET_TEAM_JOIN_REQUESTS_SUCCESS: {
      return {
        ...state,
        joinRequests: {
          ...state.joinRequests,
          existing: payload,
          loading: false
        }
      };
    }

    case c.GET_TEAM_JOIN_REQUESTS_ERROR: {
      return {
        ...state,
        joinRequests: {
          ...state.joinRequests,
          error: payload,
          loading: false
        }
      };
    }

    case c.GET_TEAM_DETAILS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_TEAM_DETAILS_SUCCESS: {
      return {
        ...state,
        details: payload,
        loading: false
      };
    }
    case c.GET_TEAM_DETAILS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.CREATE_NEW_TEAM: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.CREATE_NEW_TEAM_SUCCESS: {
      return {
        ...state,
        details: payload,
        loading: false
      };
    }
    case c.CREATE_NEW_TEAM_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.UPDATE_TEAM_DETAILS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.UPDATE_TEAM_DETAILS_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload
        },
        loading: true
      };
    }

    case c.UPDATE_TEAM_DETAILS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.DELETE_INVITE_USER: {
      return {
        ...state,
        deleteInviteUserError: null,
        deleteInviteUserLoading: true
      };
    }

    case c.DELETE_INVITE_USER_SUCCESS: {
      const updatedInvitations = state.invitations.filter((invite) => invite.id !== payload);

      return {
        ...state,
        invitations: updatedInvitations,
        deleteInviteUserError: null,
        deleteInviteUserLoading: false
      };
    }

    case c.DELETE_INVITE_USER_ERROR: {
      return {
        ...state,
        deleteInviteUserLoading: false,
        deleteInviteUserError: payload
      };
    }

    case c.CHANGE_TEAM_NAME: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case c.CHANGE_TEAM_NAME_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload
        },
        loading: true
      };
    }

    case c.CHANGE_TEAM_NAME_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.CHANGE_TEAM_USER_ROLE: {
      return {
        ...state,
        loadingChangeTeamRole: true
      };
    }

    case c.CHANGE_TEAM_USER_ROLE_SUCCESS: {
      const users = state.details?.users
        ? state.details.users.map((user) => {
            if (user.id === payload.user) {
              return {
                ...user,
                teams: user.teams.map((team) => {
                  if (team.id === payload.team) {
                    return {
                      ...team,
                      pivot: {
                        team_role: payload.role,
                        title: payload.title
                      }
                    };
                  }

                  return team;
                })
              };
            }

            return user;
          })
        : [];

      return {
        ...state,
        loadingChangeTeamRole: false,
        details: {
          ...state.details,
          users
        }
      };
    }

    case c.CHANGE_TEAM_USER_ROLE_ERROR: {
      return {
        ...state,
        loadingChangeTeamRole: false,
        error: payload
      };
    }

    case c.DELETE_USER_FROM_TEAM: {
      return {
        ...state,
        loadingDeleteUserFromTeam: true
      };
    }

    case c.DELETE_USER_FROM_TEAM_SUCCESS: {
      const updatedTeamUsers = state.details.users.filter((user) => user.id !== payload.user);

      return {
        ...state,
        loadingDeleteUserFromTeam: false,
        details: {
          ...state.details,
          users: updatedTeamUsers
        }
      };
    }

    case c.DELETE_USER_FROM_TEAM_ERROR: {
      return {
        ...state,
        loadingDeleteUserFromTeam: false,
        error: payload
      };
    }

    case c.DELETE_TEAM: {
      return {
        ...state,
        loadingDeleteTeam: true
      };
    }

    case c.DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        loadingDeleteTeam: false,
        details: {}
      };
    }

    case c.DELETE_TEAM_ERROR: {
      return {
        ...state,
        loadingDeleteTeam: false,
        error: payload
      };
    }

    default:
      return state;
  }
};
