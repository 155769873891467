import { gql } from '@apollo/client';

export const UNIT_ALL_DETAILS = gql`
  fragment UnitDetails on Unit {
    id
    title
    media {
      id
      media_url
      media_name
      download_url
    }
  }
`;

export const UNIT_ITEM_ALL_DETAILS = gql`
  fragment UnitItemDetails on UnitItem {
    id
    title
    media {
      id
      media_url
      media_name
      download_url
    }
  }
`;

const CREATE_UNIT = gql`
  ${UNIT_ALL_DETAILS}
  ${UNIT_ITEM_ALL_DETAILS}
  mutation createUnit($input: UnitInput!) {
    unit(input: $input) {
      ...UnitDetails
      unititem {
        ...UnitItemDetails
      }
    }
  }
`;

const CREATE_UNIT_ITEM = gql`
  ${UNIT_ITEM_ALL_DETAILS}
  mutation createUnitItem($input: UnitItemInput!) {
    unititem(input: $input) {
      ...UnitItemDetails
    }
  }
`;

const EDIT_UNIT = gql`
  ${UNIT_ALL_DETAILS}
  ${UNIT_ITEM_ALL_DETAILS}
  mutation editUnit($input: UnitInput!) {
    unit(input: $input) {
      ...UnitDetails
      unititem {
        ...UnitItemDetails
      }
    }
  }
`;

const DELETE_UNIT = gql`
  mutation deleteUnit($id: Int!) {
    deleteunit(id: $id) {
      status
    }
  }
`;

const DELETE_UNIT_ITEM = gql`
  mutation deleteUnitItem($id: ID!) {
    deleteunititem(id: $id) {
      id
      title
    }
  }
`;

const EDIT_UNIT_ITEM = gql`
  ${UNIT_ITEM_ALL_DETAILS}
  mutation editUnitItem($input: UnitItemInput!) {
    unititem(input: $input) {
      ...UnitItemDetails
    }
  }
`;

export { CREATE_UNIT, EDIT_UNIT, DELETE_UNIT, DELETE_UNIT_ITEM, CREATE_UNIT_ITEM, EDIT_UNIT_ITEM };
