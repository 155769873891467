import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import omitDeep from 'omit-deep';

class ApiClient {
  constructor() {
    this.token = null;
    this.apiClient = null;
    this.httpLink = null;
    this.authLink = null;
    this.uploadLink = null;
    this.errorLink = null;
  }

  /**
   * @param {string|null} token
   */
  setJwtToken(token) {
    this.token = token;
  }

  init() {
    this.authLink = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }) => ({
        headers: {
          authorization: `Bearer ${this.token}`,
          ...headers
        }
      }));

      if (operation.variables) {
        operation.variables = omitDeep(operation.variables, '__typename');
      }

      return forward(operation);
    });

    this.uploadLink = createUploadLink();

    this.httpLink = new HttpLink({
      uri: '/graphql',
      headers: {
        authorization: this.token ? `Bearer ${this.token}` : null
        // "Content-Type": "application/json",
      }
    });

    this.errorLink = onError(({ response, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        const errors = [];
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.log(
            `[GraphQL error]: `,
            `Message: `,
            message,
            `Location: `,
            locations,
            `Path: `,
            path
          );

          if (typeof extensions !== 'undefined' && typeof extensions.category !== 'undefined') {
            errors.push({
              message: extensions.reason || message,
              category: extensions.category,
              errors: extensions.errors || {},
              validation: extensions.validation || {}
            });
          }
        });
        response.errors = errors;
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    this.apiClient = new ApolloClient({
      link: from([this.errorLink, this.authLink, this.httpLink]),
      cache: new InMemoryCache({
        dataIdFromObject: (o) => {
          o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`;
        }
      })
    });

    this.uploadClient = new ApolloClient({
      link: from([this.errorLink, this.authLink, this.uploadLink]),
      cache: new InMemoryCache({
        dataIdFromObject: (o) => {
          o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`;
        }
      })
    });

    return this;
  }

  mutate(mutation, variables, isUpload = false) {
    if (isUpload) {
      return this.uploadClient.mutate({
        mutation,
        variables
      });
    }

    return this.apiClient.mutate({
      mutation,
      variables
    });
  }

  query(query, variables, options = {}) {
    return this.apiClient.query({
      query,
      variables,
      ...options
    });
  }

  /**
   * @param {*} args
   */
  static create(...args) {
    const client = new this(...args);

    return client.init();
  }

  /**
   * @return {String}
   */
  static get LS_KEY() {
    return '__jwtToken__';
  }

  /**
   * @return {String}
   */
  static get CART_KEY() {
    return '__cartToken__';
  }
}

export default ApiClient;
