export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_ERROR = 'EDIT_PRODUCT_ERROR';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

export const EDIT_FILE = 'EDIT_FILE';
export const EDIT_FILE_SUCCESS = 'EDIT_FILE_SUCCESS';
export const EDIT_FILE_ERROR = 'EDIT_FILE_ERROR';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const EMPTY_PRODUCT = 'EMPTY_PRODUCT';
export const EMPTY_PRODUCT_SUCCESS = 'EMPTY_PRODUCT_SUCCESS';
export const EMPTY_PRODUCT_ERROR = 'EMPTY_PRODUCT_ERROR;'

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const GET_TEAM_PRODUCTS = 'GET_TEAM_PRODUCTS';
export const GET_TEAM_PRODUCTS_SUCCESS = 'GET_TEAM_PRODUCTS_SUCCESS';
export const GET_TEAM_PRODUCTS_ERROR = 'GET_TEAM_PRODUCTS_ERROR';

export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS';
export const UPDATE_PRODUCT_STATUS_SUCCESS = 'UPDATE_PRODUCT_STATUS_SUCCESS';
export const UPDATE_PRODUCT_STATUS_ERROR = 'UPDATE_PRODUCT_STATUS_ERROR';

export const SAVE_PREV_OPENED_PRODUCT = 'SAVE_PREV_OPENED_PRODUCT';
