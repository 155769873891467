import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from './MainLayout';

function MainLayoutRoute({ render, userData, allowedRoles, onlyPublic, ...rest }) {
  const path = rest.path;
  const isDemoMode = process.env.MIX_DEMO_MODE === 'true';
  const isOrdersEnable = process.env.MIX_ENABLE_ORDERS === 'true';
  const isAllowed = useMemo(() => {
    const restrictedRoutes = [/*'/register',*/ '/forgot-password'];
    const restrictedOrderRouteForCustomer = ['/orders', '/cart'];

    if (restrictedRoutes.includes(path) && isDemoMode) {
      return false;
    }

    if (
      restrictedOrderRouteForCustomer.includes(path) &&
      !userData?.roles?.length &&
      !isOrdersEnable
    ) {
      return false;
    }

    if (allowedRoles && allowedRoles.length > 0) {
      if (!userData?.id) {
        return false;
      }

      if (allowedRoles.includes('user')) {
        return true;
      }

      return (
        allowedRoles
          .map((role) => userData.roles.map((userRole) => userRole.role).includes(role))
          .filter((isThere) => isThere).length > 0
      );
    }

    return true;
  }, [userData, allowedRoles, path, isDemoMode]);
  console.log(
    '🚀 ~ file: main-layout-route.js ~ line 9 ~ MainLayoutRoute ~ onlyPublic',
    userData,
    isAllowed,
    allowedRoles,
    onlyPublic
  );

  let redirectTo = '/';
  if (!userData?.id && !isDemoMode) {
    redirectTo = '/login';
  }

  console.log(
    '🚀 ~ file: main-layout-route.js ~ line 25 ~ MainLayoutRoute ~ isAllowed || (userData?.id && !notAuth)',
    rest.path,
    !isAllowed || (userData?.id && onlyPublic)
  );

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        !isAllowed || (userData?.id && onlyPublic) ? (
          <Redirect to={redirectTo} />
        ) : (
          <MainLayout>{render(matchProps)}</MainLayout>
        )
      }
    />
  );
}

MainLayoutRoute.propTypes = {
  render: PropTypes.func.isRequired,
  onlyPublic: PropTypes.bool,
  allowedRoles: PropTypes.array
};

MainLayoutRoute.defaultProps = {
  onlyPublic: false,
  allowedRoles: []
};

const mapStateToProps = (state) => ({
  userData: state.auth?.data,
  loading: state.auth?.loading
});

export default connect(mapStateToProps)(MainLayoutRoute);
