import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  GET_TEAMS_Q,
  UPDATE_TEAM_DETAILS_M,
  CHANGE_TEAM_NAME_M,
  CREATE_NEW_TEAM_M,
  DELETE_INVITE_USER_M,
  GET_TEAM_DETAILS_Q,
  CHANGE_TEAM_USER_ROLE_M,
  DELETE_USER_FROM_TEAM_M,
  DELETE_TEAM_Q,
  GET_TEAM_INVITATIONS_Q, GET_JOIN_REQUESTS_Q, APPROVE_JOIN_REQUEST_M
} from "../../api/team";

import api from '../../api';

import {
  GET_TEAMS,
  UPDATE_TEAM_DETAILS,
  GET_TEAM_DETAILS,
  CHANGE_TEAM_USER_ROLE,
  DELETE_USER_FROM_TEAM,
  DELETE_TEAM,
  GET_TEAM_INVITATIONS,
  CREATE_NEW_TEAM,
  CHANGE_TEAM_NAME,
  DELETE_INVITE_USER,
  GET_TEAM_JOIN_REQUESTS, APPROVE_JOIN_REQUEST
} from "./types";

import { ATTACH_USER_TO_TEAM } from '../auth/types';

import {
  getTeamsSuccess,
  getTeamsError,
  createNewTeamSuccess,
  createNewTeamError,
  updateTeamDetailsSuccess,
  updateTeamDetailsError,
  changeTeamNameSuccess,
  changeTeamNameError,
  getTeamDetailsSuccess,
  getTeamDetailsError,
  changeTeamUserRoleSuccess,
  changeTeamUserRoleError,
  deleteUserFromTeamSuccess,
  deleteUserFromTeamError,
  deleteTeamSuccess,
  deleteTeamError,
  getTeamInvitationsSuccess,
  getTeamInvitationsError,
  deleteInviteUserSuccess,
  deleteInviteUserError,
  getTeamJoinRequestsSuccess,
  getTeamJoinRequestsError
} from './actions';

import { attachUserToTeamSuccess, attachUserToTeamError } from '../auth/actions';

import {
  changeCurrentTeam,
  changeCurrentTeamTitle,
  changeCurrentTeamRole,
  getUserMe
} from '../auth/actions';

function* deleteTeam({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: DELETE_TEAM_Q,
      variables: {
        id: payload.id
      }
    });

    yield put(deleteTeamSuccess(payload));
    toastr.success('The team has been deleted');
  } catch (error) {
    yield put(
      deleteTeamError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

function* deleteUserFromTeam({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: DELETE_USER_FROM_TEAM_M,
      variables: {
        input: payload
      }
    });
    yield put(deleteUserFromTeamSuccess(payload));
  } catch (error) {
    yield put(
      deleteUserFromTeamError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

function* changeTeamsRole({ payload: { user, team, role, cb, title } }) {
  try {
    yield api.apiClient.mutate({
      mutation: CHANGE_TEAM_USER_ROLE_M,
      variables: {
        input: {
          user,
          team,
          role,
          ...(title ? { title } : {})
        }
      }
    });

    yield put(changeTeamUserRoleSuccess({ user, team, role, ...(title ? { title } : {}) }));
    toastr.success('User role was changed');

    if (title) {
      yield put(changeCurrentTeamTitle(title, team));
    }

    if (role === 'teamowner') {
      yield put(
        changeCurrentTeamRole({
          id: team,
          role: 'teamadmin'
        })
      );
    }

    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    yield put(
      changeTeamUserRoleError([
        {
          category: 'generic',
          reason: error.message
        }
      ]),
      toastr.error(error.message)
    );
  }
}

function* attachUserToTeam({ payload: { user, team, role, cb, title } }) {
  try {
    yield api.apiClient.mutate({
      mutation: CHANGE_TEAM_USER_ROLE_M,
      variables: {
        input: {
          user,
          team,
          role,
          ...(title ? { title } : {})
        }
      }
    });
    yield put(attachUserToTeamSuccess({ user, team, role, cb }));
    toastr.success('You have claimed this campus');

    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    yield put(
      attachUserToTeamError([
        {
          category: 'generic',
          reason: error.message
        }
      ]),
      toastr.error(error.message)
    );
  }
}

export function* watchAttachUserToTeam() {
  yield takeEvery(ATTACH_USER_TO_TEAM, attachUserToTeam);
}

function* getTeams() {
  try {
    const response = yield api.apiClient.query({
      query: GET_TEAMS_Q
    });
    yield put(getTeamsSuccess(response.data));
  } catch (error) {
    yield put(
      getTeamsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetTeams() {
  yield takeEvery(GET_TEAMS, getTeams);
}

function* getTeamInvitations({ payload: { teamId } }) {
  try {
    const response = yield api.query(
      GET_TEAM_INVITATIONS_Q,
      {
        team_id: parseInt(teamId),
        status: true
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
    yield put(getTeamInvitationsSuccess(response.data.invitations.data));
  } catch (error) {
    yield put(
      getTeamInvitationsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetTeamInvitations() {
  yield takeEvery(GET_TEAM_INVITATIONS, getTeamInvitations);
}

function* getTeamJoinRequests({ payload: { status = 0, teamId } }) {
  try {
    const response = yield api.query(
      GET_JOIN_REQUESTS_Q,
      {
        team_id: parseInt(teamId),
        status: 0
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
    yield put(getTeamJoinRequestsSuccess(response.data.joinrequests));
  } catch (error) {
    yield put(getTeamJoinRequestsError('Error receiving join requests'));
  }
}

export function* watchGetTeamJoinRequests() {
  yield takeEvery(GET_TEAM_JOIN_REQUESTS, getTeamJoinRequests);
}

function* approveRequestToJoinTeam({ payload: id }) {
  try {
    console.log('BERLINNN');
    console.log(id);
    const response = yield api.mutate(APPROVE_JOIN_REQUEST_M, {
      input: {
        id
      }
    });
  } catch (error) {
    console.error('Error');
  }
}

export function* watchApproveRequestToJoinTeam() {
  yield takeEvery(APPROVE_JOIN_REQUEST, approveRequestToJoinTeam);
}

function* createNewTeam({ payload, currentUser, cb }) {
  try {
    const response = yield api.mutate(CREATE_NEW_TEAM_M, {
      input: {
        name: payload.name,
        school_name: payload.school_name,
        location: payload.location,
        address: payload.address,
        team_details: `${JSON.stringify({
          email_address: payload.email_address,
          web_page: payload.web_page,
          phone: payload.phone
        })}`
      }
    });
    // yield api.apiClient.mutate({
    //   mutation: CHANGE_TEAM_USER_ROLE_M,
    //   variables: {
    //     input: {
    //       user: currentUser.id,
    //       team: response.data.team.id,
    //       role: 'teamowner'
    //     }
    //   }
    // });

    // yield put(
    //   changeCurrentTeam({
    //     ...response.data.team,
    //     pivot: {
    //       team_role: 'teamowner'
    //     }
    //   })
    // );

    yield put(getUserMe(true));
    yield put(createNewTeamSuccess(payload));
    toastr.success('A new team was created');

    if (typeof cb === 'function') {
      cb(response.data.team.id);
    }
  } catch (error) {
    yield put(
      createNewTeamError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchCreateNewTeam() {
  yield takeEvery(CREATE_NEW_TEAM, createNewTeam);
}

function* updateTeamDetails({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: UPDATE_TEAM_DETAILS_M,
      variables: {
        input: {
          id: payload.id,
          name: payload.name,
          school_name: payload.school_name,
          location: payload.location,
          address: payload.address,
          team_details: `${JSON.stringify({
            email_address: payload.school_info?.email_address,
            web_page: payload.school_info?.web_page,
            phone: payload.school_info?.phone
          })}`
        }
      }
    });

    yield put(updateTeamDetailsSuccess(payload));
    toastr.success('The team has been successfully updated');
  } catch (error) {
    yield put(
      updateTeamDetailsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchUpdateTeamDetails() {
  yield takeEvery(UPDATE_TEAM_DETAILS, updateTeamDetails);
}

function* changeTeamName({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: CHANGE_TEAM_NAME_M,
      variables: {
        input: payload
      }
    });

    yield put(changeTeamNameSuccess(payload));
    toastr.success('The team name has been changed');
    yield put(
      changeCurrentTeam({
        name: payload.name,
        id: payload.id
      })
    );
  } catch (error) {
    yield put(
      changeTeamNameError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchChangeTeamName() {
  yield takeEvery(CHANGE_TEAM_NAME, changeTeamName);
}

function* getTeamDetails({ payload }) {
  try {
    const response = yield api.query(
      GET_TEAM_DETAILS_Q,
      {
        id: payload
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
    yield put(getTeamDetailsSuccess(response.data.team));
  } catch (error) {
    yield put(
      getTeamDetailsError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

function* deleteInviteUser({ payload }) {
  try {
    const response = yield api.mutate(DELETE_INVITE_USER_M, {
      id: payload
    });
    yield put(deleteInviteUserSuccess(response.data));
    toastr.success('The invitation was cancelled');
  } catch (error) {
    yield put(
      deleteInviteUserError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchDeleteInviteUser() {
  yield takeEvery(DELETE_INVITE_USER, deleteInviteUser);
}

export function* watchGetTeamDetails() {
  yield takeEvery(GET_TEAM_DETAILS, getTeamDetails);
}

export function* watchChangeTeamUserRole() {
  yield takeEvery(CHANGE_TEAM_USER_ROLE, changeTeamsRole);
}

export function* watchDeleteUserFromTeam() {
  yield takeEvery(DELETE_USER_FROM_TEAM, deleteUserFromTeam);
}

export function* watchDeleteTeam() {
  yield takeEvery(DELETE_TEAM, deleteTeam);
}

function* teamsSaga() {
  yield all([
    fork(watchDeleteInviteUser),
    fork(watchChangeTeamName),
    fork(watchCreateNewTeam),
    fork(watchUpdateTeamDetails),
    fork(watchGetTeams),
    fork(watchGetTeamDetails),
    fork(watchChangeTeamUserRole),
    fork(watchAttachUserToTeam),
    fork(watchDeleteUserFromTeam),
    fork(watchDeleteTeam),
    fork(watchGetTeamInvitations),
    fork(watchGetTeamJoinRequests),
    fork(watchApproveRequestToJoinTeam)
  ]);
}

export default teamsSaga;
