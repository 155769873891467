import * as c from './types';

export const getGrades = (payload) => ({
  type: c.GET_GRADES,
  payload
});

export const getGradesSuccess = (payload) => ({
  type: c.GET_GRADES_SUCCESS,
  payload
});

export const getGradesError = (error) => ({
  type: c.GET_GRADES_ERROR,
  error
});

export const addNewGrade = (title, id) => ({
  type: c.ADD_NEW_GRADE,
  payload: { title, id }
});
