import { gql } from '@apollo/client';

const DELETE_TEAM_Q = gql`
  mutation deleteTeam($id: ID!) {
    deleteteam(id: $id) {
      id
    }
  }
`;

const DELETE_USER_FROM_TEAM_M = gql`
  mutation deleteUserFromTeam($input: DetachUserFromTeamInput!) {
    detachuserfromteam(input: $input) {
      user
      team
    }
  }
`;

const CHANGE_TEAM_USER_ROLE_M = gql`
  mutation changeTeamUserRole($input: AttachUserToTeamInput!) {
    attachusertoteam(input: $input) {
      user
      team
      title
    }
  }
`;

const GET_TEAMS_Q = gql`
  query getTeams($first: Int, $page: Int, $name: String) {
    teams(first: $first, page: $page, name: $name) {
      data {
        id
        name
        school_name
        school_number
        location
        address
        school_info {
          email_address
          web_page
          phone
          fax
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_UNCLAIMED_TEAMS_Q = gql`
  query unclaimedTeams($name: String) {
    teams(name: $name) {
      data {
        id
        name
        school_name
        school_number
        location
        address
        school_info {
          email_address
          web_page
          phone
          fax
        }
      }
    }
  }
`;

export const GET_TEAM_INVITATIONS_Q = gql`
  query getTeamInvitations($status: Boolean, $team_id: Int) {
    invitations(status: $status, team_id: $team_id) {
      data {
        id
        email
        created_at
        status
        time_status
      }
    }
  }
`;

export const GET_JOIN_REQUESTS_Q = gql`
  query getJoinRequests($status: Int, $user_id: Int, $team_id: Int) {
    joinrequests(status: $status, user_id: $user_id, team_id: $team_id) {
      id
      user {
        id
        name
        email
      }
      team {
        id
      }
      status
      created_at
      updated_at
    }
  }
`;

export const APPROVE_JOIN_REQUEST_M = gql`
  mutation approveJoinRequest($input: ApproveJoinRequest!) {
    approveJoinRequest(input: $input) {
      id
      user {
        id
        name
        email
      }
      team {
        id
      }
      status
      created_at
      updated_at
    }
  }
`;

const UPDATE_TEAM_DETAILS_M = gql`
  mutation updateTeamDetails($input: TeamInput!) {
    team(input: $input) {
      id
      name
      school_name
      location
      address
      school_info {
        email_address
        web_page
        fax
        phone
      }
    }
  }
`;

const CHANGE_TEAM_NAME_M = gql`
  mutation changeTeamName($input: TeamInput!) {
    team(input: $input) {
      name
    }
  }
`;

const DELETE_INVITE_USER_M = gql`
  mutation deleteInviteUser($id: ID!) {
    deleteInvitation(id: $id) {
      id
    }
  }
`;

const CREATE_NEW_TEAM_M = gql`
  mutation createNewTeam($input: TeamInput!) {
    team(input: $input) {
      id
      name
      school_name
      location
      address
      school_info {
        email_address
        web_page
        phone
      }
    }
  }
`;

const GET_TEAM_DETAILS_Q = gql`
  query TeamDetails($id: ID) {
    team(id: $id) {
      id
      name
      spark
      school_name
      school_number
      location
      address
      pivot {
        team_role
      }
      users {
        id
        name
        email
        roles {
          id
          role
        }
        teams {
          id
          name
          spark
          pivot {
            team_role
          }
        }
        cart_items {
          id
          product {
            id
          }
          qty
          user_id
        }
      }
      school_info {
        email_address
        web_page
        fax
        principal
        phone
      }
    }
  }
`;

export {
  GET_TEAMS_Q,
  GET_UNCLAIMED_TEAMS_Q,
  GET_TEAM_DETAILS_Q,
  DELETE_INVITE_USER_M,
  CREATE_NEW_TEAM_M,
  UPDATE_TEAM_DETAILS_M,
  CHANGE_TEAM_NAME_M,
  CHANGE_TEAM_USER_ROLE_M,
  DELETE_USER_FROM_TEAM_M,
  DELETE_TEAM_Q
};
