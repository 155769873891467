import * as c from './types';

export const getCart = (user_id, team_id) => ({
  type: c.GET_CART,
  payload: {
    user_id,
    team_id
  }
});

export const getCartSuccess = (payload) => ({
  type: c.GET_CART_SUCCESS,
  payload
});

export const getCartError = (error) => ({
  type: c.GET_CART_ERROR,
  error
});

export const addToCart = (userId, productId, qty, teamId) => ({
  type: c.ADD_TO_CART,
  payload: {
    userId,
    productId,
    qty,
    teamId
  }
});

export const addToCartSuccess = (payload) => ({
  type: c.ADD_TO_CART_SUCCESS,
  payload
});

export const addToCartError = (error) => ({
  type: c.ADD_TO_CART_ERROR,
  error
});

export const removeFromCart = (id) => ({
  type: c.REMOVE_FROM_CART,
  payload: {
    id
  }
});

export const removeFromCartSuccess = (payload) => ({
  type: c.REMOVE_FROM_CART_SUCCESS,
  payload
});

export const removeFromCartError = (error) => ({
  type: c.REMOVE_FROM_CART_ERROR,
  error
});

export const requestQuote = (payload) => ({
  type: c.REQUEST_QUOTE,
  payload
});

export const requestQuoteSuccess = (payload) => ({
  type: c.REQUEST_QUOTE_SUCCESS,
  payload
});

export const requestQuoteError = (error) => ({
  type: c.REQUEST_QUOTE_ERROR,
  error
});

export const purchaseOrder = (teamId, paymentId, history, existingOrderId, cb) => ({
  type: c.PURCHASE_ORDER,
  payload: {
    teamId,
    paymentId,
    history,
    existingOrderId,
    cb
  }
});

export const purchaseOrderSuccess = (payload) => ({
  type: c.PURCHASE_ORDER_SUCCESS,
  payload
});

export const purchaseOrderError = (error) => ({
  type: c.PURCHASE_ORDER_ERROR,
  payload: error
});
