import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { GET_CART_Q, ADD_TO_CART_M, REMOVE_FROM_CART_M, REQUEST_QUOTE_M } from '../../api/cart';

import {
  SAVE_ORDER_M,
  CHANGE_ORDER_STATUS_M,
  REQUEST_CHARGE_ORDER_M,
  REQUEST_STRIPE_M, DELETE_ORDER_M
} from '../../api/order';

import api, { CART_KEY } from '../../api';
import { GET_CART, ADD_TO_CART, REMOVE_FROM_CART, REQUEST_QUOTE, PURCHASE_ORDER } from './types';
import {
  getCartSuccess,
  getCartError,
  addToCartSuccess,
  addToCartError,
  removeFromCartSuccess,
  removeFromCartError,
  requestQuoteSuccess,
  requestQuoteError,
  purchaseOrderSuccess,
  purchaseOrderError
} from './actions';

function* getCart({ payload }) {
  try {
    const response = yield api.query(
      GET_CART_Q,
      {
        userId: window.localStorage.getItem(CART_KEY),
        ...(payload.team_id ? { teamId: payload.team_id } : {})
      },
      {
        fetchPolicy: 'no-cache'
      }
    );
    yield put(getCartSuccess(response.data.cartitems));
  } catch (error) {
    yield put(
      getCartError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_CART, getCart);
}

function* addToCart({ payload }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: ADD_TO_CART_M,
      variables: {
        userId: window.localStorage.getItem(CART_KEY),
        productId: payload.productId,
        qty: payload.qty,
        ...(payload.teamId ? { teamId: payload.teamId } : {})
      }
    });

    if (payload.userId === null) {
      window.localStorage.setItem(CART_KEY, response.data.addtocart[0].user_id);
    }

    yield put(addToCartSuccess(response.data.addtocart));
    toastr.success('Product has been added to your cart');
  } catch (error) {
    yield put(
      addToCartError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error(error.message);
  }
}

export function* watchAddToCart() {
  yield takeEvery(ADD_TO_CART, addToCart);
}

function* removeFromCart({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: REMOVE_FROM_CART_M,
      variables: {
        id: payload.id
      }
    });

    yield put(removeFromCartSuccess(payload.id));
    toastr.success('Product has been removed from your cart');
  } catch (error) {
    yield put(
      removeFromCartError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error(error.message);
  }
}

export function* watchRemoveFromCart() {
  yield takeEvery(REMOVE_FROM_CART, removeFromCart);
}

function* requestQuote({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: REQUEST_QUOTE_M,
      variables: {
        team: payload.team
      }
    });

    yield put(requestQuoteSuccess(payload));
    toastr.success('Request a Quote success');
  } catch (error) {
    yield put(
      requestQuoteError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
    toastr.error('Request a Quote failed');
  }
}

export function* watchRequestQuote() {
  yield takeEvery(REQUEST_QUOTE, requestQuote);
}

function* purchaseOrder({ payload: { teamId, paymentId, history, existingOrderId = null, cb } }) {
  let orderId;
  try {
    yield api.query(REQUEST_STRIPE_M);

    if (!existingOrderId) {
      const quoteResponse = yield api.mutate(SAVE_ORDER_M, {
        team: teamId
      });
      orderId = quoteResponse.data.order.id;
    }

    const chargeorderResponse = yield api.mutate(REQUEST_CHARGE_ORDER_M, {
      id: paymentId,
      order_id: existingOrderId || orderId
    });

    if (existingOrderId || orderId) {
      yield api.mutate(CHANGE_ORDER_STATUS_M, {
        id: existingOrderId || orderId,
        status: 'Completed'
      });
    }

    yield put(purchaseOrderSuccess(chargeorderResponse));
    toastr.success('Payment Successfull');
    history.push('/');

    if (typeof cb === 'function') {
      cb();
    } else {
      history.push('/');
    }
  } catch (error) {
    if (orderId || existingOrderId) {
      history.push({
        pathname: `/order-details/${orderId ?? existingOrderId}`,
        state: { isDeclined: true }
      });
    }

    if (error.message === 'Internal server error') {
      history.location?.state?.isDeclined
        ? toastr.error('Your card was declined')
        : toastr.error(error.message);
    } else {
      toastr.error(error.message);
    }
    yield put(
      purchaseOrderError([
        {
          category: 'generic',
          reason: error.message,
          orderId: existingOrderId || orderId
        }
      ])
    );
  }
}

export function* watchPurchaseOrder() {
  yield takeEvery(PURCHASE_ORDER, purchaseOrder);
}

function* cartSaga() {
  yield all([
    fork(watchGetCart),
    fork(watchAddToCart),
    fork(watchRemoveFromCart),
    fork(watchRequestQuote),
    fork(watchPurchaseOrder)
  ]);
}

export default cartSaga;
