import * as c from './types';

export const getSubjects = (payload) => ({
  type: c.GET_SUBJECTS,
  payload
});

export const getSubjectsSuccess = (payload) => ({
  type: c.GET_SUBJECTS_SUCCESS,
  payload
});

export const getSubjectsError = (error) => ({
  type: c.GET_SUBJECTS_ERROR,
  error
});

export const addNewSubject = (title, id) => ({
  type: c.ADD_NEW_SUBJECT,
  payload: { title, id }
});
