import * as c from './types';

const initialState = {
  data: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case c.GET_PRODUCT_TYPES: {
      return {
        ...state,
        data: payload,
        loading: true
      };
    }
    case c.GET_PRODUCT_TYPES_SUCCESS: {
      return {
        ...state,
        data: payload.producttypes.data,
        loading: false
      };
    }
    case c.GET_PRODUCT_TYPES_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    default:
      return state;
  }
};
