import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Helmet from 'react-helmet';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import store from './store';
import '../sass/global.scss';

import apiClient from './api/client';
import AppMiddleware from './AppMiddleware';

const USERSNAP_GLOBAL_API_KEY = process.env.MIX_USERSNAP_GLOBAL_API_KEY;
const USERSNAP_API_KEY = process.env.MIX_USERSNAP_API_KEY;
const SENTRY_DSN = process.env.MIX_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

function MainApp() {
  return (
    <ApolloProvider client={apiClient}>
      {USERSNAP_GLOBAL_API_KEY && USERSNAP_API_KEY && (
        <Helmet>
          <script type="text/javascript">
            {`
                window.onUsersnapCXLoad = function(api) {
                  api.init();
                  api.show('${USERSNAP_API_KEY}');
                  window.Usersnap = api;
                }
                var script = document.createElement('script');
                script.defer = 1;
                script.src = 'https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad';
                document.getElementsByTagName('head')[0].appendChild(script);
            `}
          </script>
        </Helmet>
      )}
      <AppMiddleware />
      <ReduxToastr
        timeOut={5000}
        // preventDuplicates
        position="top-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </ApolloProvider>
  );
}

export default MainApp;

ReactDOM.render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.getElementById('app')
);
