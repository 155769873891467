import * as c from './types';

const initialState = {
  data: [],
  error: null,
  loading: false,
  order: {},
  products: [],
  teamOrders: [],
  myOrders: [],
  pagination: {},
  reminder: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case c.GET_ORDERS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        ...(payload.isTeam
          ? { teamOrders: payload.data, pagination: payload.paginatorInfo }
          : { data: payload.data, pagination: payload.paginatorInfo }),
        order: {},
        loading: false
      };
    }
    case c.GET_ORDERS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.GET_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_ORDER_SUCCESS: {
      return {
        ...state,
        order: payload,
        loading: false
      };
    }
    case c.GET_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.GET_ORDER_ACTIVITY: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_ORDER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        order: payload,
        loading: false
      };
    }
    case c.GET_ORDER_ACTIVITY_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.CREATE_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case c.CREATE_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.CHANGE_ORDER_STATUS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.CHANGE_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: {
          ...state.order,
          id: payload.id,
          status: payload.status
        }
      };
    }

    case c.CHANGE_ORDER_STATUS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.SAVE_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.SAVE_ORDER_SUCCESS: {
      const newList = [...state.data, payload];

      return {
        ...state,
        data: newList,
        order: payload,
        loading: false
      };
    }
    case c.SAVE_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.SAVE_ADMIN_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.SAVE_ADMIN_ORDER_SUCCESS: {
      const newList = [...state.data, payload];

      return {
        ...state,
        data: newList,
        order: payload,
        loading: false
      };
    }
    case c.SAVE_ADMIN_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }

    case c.ADD_DISCOUNT_TO_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.ADD_DISCOUNT_TO_ORDER_SUCCESS: {
      return {
        ...state,
        order: {
          ...state.order,
          discount: payload.discount,
          topay: payload.topay,
          status: payload.status
        },
        loading: false
      };
    }
    case c.ADD_DISCOUNT_TO_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.APPROVE_PO: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.APPROVE_PO_SUCCESS: {
      return {
        ...state,
        order: {
          ...state.order,
          status: payload.status
        },
        loading: false
      };
    }
    case c.APPROVE_PO_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.SAVE_ORDER_ITEM: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.SAVE_ORDER_ITEM_SUCCESS: {
      return {
        ...state,
        products: payload.product,
        loading: false
      };
    }
    case c.SAVE_ORDER_ITEM_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.SEND_REMINDER: {
      return {
        ...state,
        error: null,
        reminder: payload,
        loading: true
      };
    }
    case c.SEND_REMINDER_SUCCESS: {
      return {
        ...state,
        reminder: payload,
        loading: false
      };
    }
    case c.SEND_REMINDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.DELETE_ORDER: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.DELETE_ORDER_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload),
        teamOrders: state.teamOrders.filter((item) => item.id !== payload),
        loading: false
      };
    }
    case c.DELETE_ORDER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.DELETE_ORDER_ITEM: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.DELETE_ORDER_ITEM_SUCCESS: {
      return {
        ...state,
        order: {
          ...state.order,
          items: state.order.items.filter((item) => !payload.find((elem) => elem.id === item.id))
        },
        loading: false
      };
    }
    case c.DELETE_ORDER_ITEM_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    case c.GET_MY_ORDERS: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case c.GET_MY_ORDERS_SUCCESS: {
      return {
        ...state,
        myOrders: payload,
        loading: false
      };
    }
    case c.GET_MY_ORDERS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false
      };
    }
    default:
      return state;
  }
};
