export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const GET_USER_ME = 'GET_USER_ME';
export const GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS';
export const GET_USER_ME_ERROR = 'GET_USER_ME_ERROR';

export const ATTACH_USER_TO_TEAM = 'ATTACH_USER_TO_TEAM';
export const ATTACH_USER_TO_TEAM_SUCCESS = 'ATTACH_USER_TO_TEAM_SUCCESS';
export const ATTACH_USER_TO_TEAM_ERROR = 'ATTACH_USER_TO_TEAM_ERROR';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';

export const REQUEST_TO_JOIN_TEAM = 'REQUEST TO JOIN';
export const REQUEST_TO_JOIN_TEAM_SUCCESS = 'REQUEST TO JOIN SUCCESS';
export const REQUEST_TO_JOIN_TEAM_ERROR = 'REQUEST TO JOIN ERROR';

export const CHANGE_CURRENT_TEAM = 'CHANGE_CURRENT_TEAM';

export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME';

export const UPDATE_TEAMS_COUNT_SUCCESS = 'UPDATE_TEAMS_COUNT_SUCCESS';

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_APP_LOADED = 'SET_APP_LOADED';

export const CHANGE_CURRENT_USER_INFO = 'CHANGE_CURRENT_USER_INFO';
export const CHANGE_CURRENT_USER_INFO_SUCCESS = 'CHANGE_CURRENT_USER_INFO_SUCCESS';
export const CHANGE_CURRENT_USER_INFO_ERROR = 'CHANGE_CURRENT_USER_INFO_ERROR';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR';

export const FETCH_INVITATION = 'FETCH_INVITATION';
export const FETCH_INVITATION_SUCCESS = 'FETCH_INVITATION_SUCCESS';
export const FETCH_INVITATION_ERROR = 'FETCH_INVITATION_ERROR';

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR';

export const ATTACH_INVITED_TEAM = 'ATTACH_INVITED_TEAM';

export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_ERROR = 'DELETE_USER_ACCOUNT_ERROR';

export const CHANGE_CURRENT_TEAM_TITLE = 'CHANGE_CURRENT_TEAM_TITLE';
export const CHANGE_CURRENT_TEAM_ROLE = 'CHANGE_CURRENT_TEAM_ROLE';
