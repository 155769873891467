import { all } from 'redux-saga/effects';

// public
import AuthSaga from './auth/saga';
import ProductSaga from './product/saga';
import GradeSaga from './grade/saga';
import SubjectSaga from './subject/saga';
import ProductTypeSaga from './productType/saga';
import TeamsSaga from './teams/saga';
import CartSaga from './cart/saga';
import OrdersSaga from './orders/saga';

export default function* appSaga() {
  yield all([
    AuthSaga(),
    ProductSaga(),
    GradeSaga(),
    SubjectSaga(),
    ProductTypeSaga(),
    TeamsSaga(),
    CartSaga(),
    OrdersSaga()
  ]);
}
