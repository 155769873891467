import * as c from './types';

export const getProductTypes = (payload) => ({
  type: c.GET_PRODUCT_TYPES,
  payload
});

export const getProductTypesSuccess = (payload) => ({
  type: c.GET_PRODUCT_TYPES_SUCCESS,
  payload
});

export const getProductTypesError = (error) => ({
  type: c.GET_PRODUCT_TYPES_ERROR,
  error
});
