import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  GET_ORDERS_Q,
  GET_ORDER_Q,
  GET_ORDER_ACTIVITY_Q,
  SEND_REMINDER_M,
  CREATE_ORDER_M,
  SAVE_ORDER_M,
  SAVE_ADMIN_ORDER_M,
  SAVE_ORDER_ITEM_M,
  DELETE_ORDER_M,
  DELETE_ORDER_ITEM_M,
  CHANGE_ORDER_STATUS_M,
  GET_MY_ORDERS_Q,
  ADD_DISCOUNT_TO_ORDER_M,
  APPROVE_PO_M
} from '../../api/order';
import api from '../../api';
import {
  GET_ORDERS,
  GET_ORDER,
  GET_ORDER_ACTIVITY,
  CREATE_ORDER,
  SAVE_ORDER,
  SAVE_ADMIN_ORDER,
  SAVE_ORDER_ITEM,
  SEND_REMINDER,
  DELETE_ORDER,
  DELETE_ORDER_ITEM,
  CHANGE_ORDER_STATUS,
  GET_MY_ORDERS,
  ADD_DISCOUNT_TO_ORDER,
  APPROVE_PO
} from './types';
import {
  getOrdersSuccess,
  getOrdersError,
  getOrderSuccess,
  getOrderError,
  getOrderActivitySuccess,
  getOrderActivityError,
  createOrderSuccess,
  createOrderError,
  saveOrderSuccess,
  saveOrderError,
  saveAdminOrderSuccess,
  saveAdminOrderError,
  sendReminderSuccess,
  sendReminderError,
  saveOrderItemSuccess,
  saveOrderItemError,
  deleteOrderSuccess,
  deleteOrderError,
  deleteOrderItemSuccess,
  deleteOrderItemError,
  changeOrderStatusSuccess,
  changeOrderStatusError,
  getMyOrdersSuccess,
  getMyOrdersError,
  addDiscountToOrderSuccess,
  addDiscountToOrderError,
  approvePoSuccess,
  approvePoError
} from './actions';

function* getOrders({ payload }) {
  try {
    const response = yield api.query(
      GET_ORDERS_Q,
      {
        ...(payload.status ? { status: payload.status } : {}),
        ...(payload.teamId ? { team_id: payload.teamId } : {}),
        ...(payload.first ? { first: payload.first } : {}),
        ...(payload.page >= 0 ? { page: payload.page } : {}),
        ...(payload.orderBy ? { orderBy: payload.orderBy } : {})
      },
      {
        fetchPolicy: 'no-cache'
      }
    );

    yield put(
      getOrdersSuccess(
        response.data.orders.data,
        response.data.orders.paginatorInfo,
        payload.teamId
      )
    );
  } catch (error) {
    yield put(
      getOrdersError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getOrders);
}

function* getOrder({ payload }) {
  try {
    const response = yield api.apiClient.query({
      query: GET_ORDER_Q,
      fetchPolicy: 'no-cache',
      variables: {
        id: payload
      }
    });
    yield put(getOrderSuccess(response.data.order));
  } catch (error) {
    yield put(
      getOrderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, getOrder);
}

function* getOrderActivity({ payload }) {
  try {
    const response = yield api.apiClient.query({
      query: GET_ORDER_ACTIVITY_Q,
      // fetchPolicy: "no-cache",
      variables: {
        id: payload
      }
    });

    yield put(getOrderActivitySuccess(response.data.order));
  } catch (error) {
    yield put(
      getOrderActivityError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetOrderActivity() {
  yield takeEvery(GET_ORDER_ACTIVITY, getOrderActivity);
}

function* sendReminder({ payload }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: SEND_REMINDER_M,
      variables: {
        id: payload
      }
    });

    yield put(sendReminderSuccess(response.data.reminder));
    toastr.success(response.data.reminder.message);
  } catch (error) {
    yield put(
      sendReminderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchSendReminder() {
  yield takeEvery(SEND_REMINDER, sendReminder);
}

function* createOrder({ payload }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: CREATE_ORDER_M,
      variables: {
        reminder: payload
      }
    });

    yield put(createOrderSuccess(response.data.order));
  } catch (error) {
    yield put(
      createOrderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchCreateOrder() {
  yield takeEvery(CREATE_ORDER, createOrder);
}

function* saveOrder({ payload }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: SAVE_ORDER_M,
      variables: {
        team: payload
      }
    });

    yield put(saveOrderSuccess(response.data.order));
    toastr.success('Order has been saved');
  } catch (error) {
    yield put(
      saveOrderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchSaveOrder() {
  yield takeEvery(SAVE_ORDER, saveOrder);
}

function* saveAdminOrder({ payload: { user_id, status, team_id, temp_discount, cb } }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: SAVE_ADMIN_ORDER_M,
      variables: {
        input: {
          user_id: user_id,
          status: status,
          team_id: team_id
        }
      }
    });

    const responseAddDiscount = yield api.apiClient.mutate({
      mutation: ADD_DISCOUNT_TO_ORDER_M,
      variables: {
        input: {
          id: response.data?.adminOrder?.id,
          user_id: parseInt(response.data?.adminOrder?.user?.id) || null,
          status: response.data?.adminOrder?.status,
          team_id: parseInt(response.data?.adminOrder?.team?.id),
          discount: temp_discount
        }
      }
    });
    yield put(saveAdminOrderSuccess(response.data?.adminOrder));

    if (temp_discount > 0) {
      yield put(addDiscountToOrderSuccess(responseAddDiscount.data.adminOrder));
    }
    toastr.success('Order has been saved');
    if (typeof cb === 'function') {
      yield cb(response.data?.adminOrder.id);
    }
  } catch (error) {
    yield put(
      saveAdminOrderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchSaveAdminOrder() {
  yield takeEvery(SAVE_ADMIN_ORDER, saveAdminOrder);
}

function* addDiscountToOrder({ payload }) {
  console.log('🚀 ~ file: saga.js ~ line 196 ~ function*addDiscountToOrder ~ payload', payload);
  try {
    const response = yield api.apiClient.mutate({
      mutation: ADD_DISCOUNT_TO_ORDER_M,
      variables: {
        input: {
          id: payload.id,
          user_id: payload.user_id || null,
          status: payload.status,
          team_id: payload.team_id,
          discount: payload.discount
        }
      }
    });

    yield put(addDiscountToOrderSuccess(response.data.adminOrder));
    toastr.success('Order has been updated');
  } catch (error) {
    yield put(
      addDiscountToOrderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchAddDiscountToOrder() {
  yield takeEvery(ADD_DISCOUNT_TO_ORDER, addDiscountToOrder);
}

function* approvePo({ payload }) {
  try {
    const response = yield api.apiClient.mutate({
      mutation: APPROVE_PO_M,
      variables: {
        id: payload.id,
        po_number: payload.po_number,
        status: payload.status,
      }
    });
    yield put(approvePoSuccess(response.data.changeOrder));
    toastr.success('Approve P.O. has been Success');
  } catch (error) {
    toastr.error(error.message);
    yield put(
      approvePoError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchApprovePo() {
  yield takeEvery(APPROVE_PO, approvePo);
}

function* saveOrderItem({
  payload: {
    payload: { order_id, price, product_id, qty },
    cb
  }
}) {
  try {
    const response = yield api.mutate(SAVE_ORDER_ITEM_M, {
      input: {
        order_id,
        price,
        product_id,
        qty
      }
    });
    yield put(saveOrderItemSuccess(response.data.orderItem));
    if (typeof cb === 'function') {
      yield cb(response.data.orderItem);
    }
  } catch (error) {
    yield put(
      saveOrderItemError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchSaveOrderItem() {
  yield takeEvery(SAVE_ORDER_ITEM, saveOrderItem);
}

function* deleteOrder({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: DELETE_ORDER_M,
      variables: {
        id: payload.id
      }
    });
    yield put(deleteOrderSuccess(payload.id));
    // yield put(getOrdersSuccess(response.data.orders.data, response.data.orders.paginatorInfo, payload.teamId));
    toastr.success('You have successfully deleted the order');
  } catch (error) {
    yield put(
      deleteOrderError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

function* deleteOrderItem({ payload: { ids, cb } }) {
  try {
    const promises = ids.map((id) =>
      api.apiClient.mutate({
        mutation: DELETE_ORDER_ITEM_M,
        variables: {
          id
        }
      })
    );;

    const response = yield Promise.all(promises);
    const newResp = response.map((elem) => elem.data.deleteorderItem);
    if (newResp.length > 0) {
      yield put(deleteOrderItemSuccess(newResp));
      toastr.success('The product has been removed');
    }

    if (typeof cb === 'function') {
      yield cb();
    }
  } catch (error) {
    yield put(
      deleteOrderItemError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchDeleteOrderItem() {
  yield takeEvery(DELETE_ORDER_ITEM, deleteOrderItem);
}

function* changeOrderStatus({ payload }) {
  try {
    yield api.apiClient.mutate({
      mutation: CHANGE_ORDER_STATUS_M,
      variables: {
        id: payload.id,
        status: payload.status
      }
    });
    yield put(changeOrderStatusSuccess(payload.id, payload.status));
    toastr.success('The order has been marked as paid');
  } catch (error) {
    yield put(
      changeOrderStatusError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchChangeOrderStatus() {
  yield takeEvery(CHANGE_ORDER_STATUS, changeOrderStatus);
}

function* getMyOrders() {
  try {
    const response = yield api.apiClient.query({
      query: GET_MY_ORDERS_Q,
      fetchPolicy: 'no-cache'
      // variables: {
      //   ...payload.status ? { status: payload.status } : {},
      //   ...payload.teamId ? { team_id: payload.teamId } : {},
      //   ...payload.first ? { first: payload.first } : {},
      //   ...payload.page >= 0 ? { page: payload.page } : {},
      // }
    });

    yield put(getMyOrdersSuccess(response.data.myorders));
  } catch (error) {
    yield put(
      getMyOrdersError([
        {
          category: 'generic',
          reason: error.message
        }
      ])
    );
  }
}

export function* watchGetMyOrders() {
  yield takeEvery(GET_MY_ORDERS, getMyOrders);
}

function* ordersSaga() {
  yield all([
    fork(watchGetOrders),
    fork(watchSendReminder),
    fork(watchGetOrder),
    fork(watchGetOrderActivity),
    fork(watchCreateOrder),
    fork(watchSaveOrder),
    fork(watchSaveAdminOrder),
    fork(watchSaveOrderItem),
    fork(watchDeleteOrder),
    fork(watchDeleteOrderItem),
    fork(watchChangeOrderStatus),
    fork(watchGetMyOrders),
    fork(watchAddDiscountToOrder),
    fork(watchApprovePo)
  ]);
}

export default ordersSaga;
