import * as c from './types';

export const authUser = (user, history, cb) => ({
  type: c.AUTH_USER,
  payload: { user, history, cb }
});

export const authUserSuccess = (user) => ({
  type: c.AUTH_USER_SUCCESS,
  payload: user
});

export const authUserError = (error) => ({
  type: c.AUTH_USER_ERROR,
  payload: error
});

export const logoutUser = (history) => ({
  type: c.LOGOUT_USER,
  payload: { history }
});

export const logoutUserSuccess = () => ({
  type: c.LOGOUT_USER_SUCCESS
});

export const logoutUserError = (error) => ({
  type: c.LOGOUT_USER_ERROR,
  payload: error
});

export const getUserMe = (isRefetch) => ({
  type: c.GET_USER_ME,
  payload: { isRefetch }
});

export const getUserMeSuccess = (user) => ({
  type: c.GET_USER_ME_SUCCESS,
  payload: user
});

export const getUserMeError = (error) => ({
  type: c.GET_USER_ME_ERROR,
  payload: error
});

export const registerUser = (user, history) => ({
  type: c.REGISTER_USER,
  payload: { user, history }
});

export const registerUserSuccess = (user) => ({
  type: c.REGISTER_USER_SUCCESS,
  payload: user
});

export const registerUserError = (error) => ({
  type: c.REGISTER_USER_ERROR,
  payload: error
});

export const inviteUser = (user, cb) => ({
  type: c.INVITE_USER,
  payload: { user, cb }
});

export const inviteUserSuccess = (user) => ({
  type: c.INVITE_USER_SUCCESS,
  payload: user
});

export const inviteUserError = (error) => ({
  type: c.INVITE_USER_ERROR,
  payload: error
});

export const requestToJoinTeam = (requestFromUserId, requestToTeamId) => ({
  type: c.REQUEST_TO_JOIN_TEAM,
  payload: { requestFromUserId, requestToTeamId }
});

export const requestToJoinTeamSuccess = (newJoinRequestId) => ({
  type: c.REQUEST_TO_JOIN_TEAM_SUCCESS,
  payload: newJoinRequestId
});

export const requestToJoinTeamError = () => ({
  type: c.REQUEST_TO_JOIN_TEAM_ERROR
});

export const changeCurrentTeam = (payload) => ({
  type: c.CHANGE_CURRENT_TEAM,
  payload
});

export const updateTeamName = (payload) => ({
  type: c.UPDATE_TEAM_NAME,
  payload
});

export const setAppIsLoading = (isLoading) => ({
  type: c.SET_APP_LOADING,
  payload: isLoading
});

export const setAppIsLoaded = (isLoaded) => ({
  type: c.SET_APP_LOADED,
  payload: isLoaded
});

export const changeCurrentUserInfo = (name) => ({
  type: c.CHANGE_CURRENT_USER_INFO,
  payload: name
});

export const changeCurrentUserInfoSuccess = (name) => ({
  type: c.CHANGE_CURRENT_USER_INFO_SUCCESS,
  payload: name
});

export const changeCurrentUserInfoError = (error) => ({
  type: c.CHANGE_CURRENT_USER_INFO_ERROR,
  payload: error
});

export const attachUserToTeam = (user, team, role, cb) => ({
  type: c.ATTACH_USER_TO_TEAM,
  payload: {
    user,
    team,
    role,
    cb
  }
});

export const attachUserToTeamSuccess = (payload) => ({
  type: c.ATTACH_USER_TO_TEAM_SUCCESS,
  payload
});

export const attachUserToTeamError = (error) => ({
  type: c.ATTACH_USER_TO_TEAM_ERROR,
  payload: error
});

export const updateTeamsCountSuccess = (id) => ({
  type: c.UPDATE_TEAMS_COUNT_SUCCESS,
  payload: id
});

export const attachInvitedTeam = (payload) => ({
  type: c.ATTACH_INVITED_TEAM,
  payload
});

export const updateUserPassword = (old_password, password, password_confirmation) => ({
  type: c.UPDATE_USER_PASSWORD,
  payload: { old_password, password, password_confirmation }
});

export const updateUserPasswordSuccess = (payload) => ({
  type: c.UPDATE_USER_PASSWORD_SUCCESS,
  payload
});

export const updateUserPasswordError = (error) => ({
  type: c.UPDATE_USER_PASSWORD_ERROR,
  payload: error
});

export const fetchInvitation = (k, cb) => ({
  type: c.FETCH_INVITATION,
  payload: { k, cb }
});

export const fetchInvitationSuccess = (payload) => ({
  type: c.FETCH_INVITATION_SUCCESS,
  payload
});

export const fetchInvitationError = (error) => ({
  type: c.FETCH_INVITATION_ERROR,
  payload: error
});

export const acceptInvitation = (k, cb, history) => ({
  type: c.ACCEPT_INVITATION,
  payload: { k, cb, history }
});

export const acceptInvitationSuccess = (payload) => ({
  type: c.ACCEPT_INVITATION_SUCCESS,
  payload
});

export const acceptInvitationError = (error) => ({
  type: c.ACCEPT_INVITATION_ERROR,
  payload: error
});

export const deleteUserAccount = (cb) => ({
  type: c.DELETE_USER_ACCOUNT,
  payload: { cb }
});

export const deleteUserAccountSuccess = (payload) => ({
  type: c.DELETE_USER_ACCOUNT_SUCCESS,
  payload
});

export const deleteUserAccountError = (error) => ({
  type: c.DELETE_USER_ACCOUNT_ERROR,
  payload: error
});

export const changeCurrentTeamTitle = (title, id) => ({
  type: c.CHANGE_CURRENT_TEAM_TITLE,
  payload: { title, id }
});

export const changeCurrentTeamRole = (payload) => ({
  type: c.CHANGE_CURRENT_TEAM_ROLE,
  payload
});

export const saveBannerMessage = (message, history) => ({
  type: c.SAVE_BANNER_MESSAGE,
  payload: { message, history }
})

export const saveBannerMessageSuccess = (payload) => ({
  type: c.SAVE_BANNER_MESSAGE_SUCCESS,
  payload
})

export const saveBannerMessageError = (error) => ({
  type: c.SAVE_BANNER_MESSAGE_ERROR,
  payload: error
})

export const getBannerMessage = () => ({
  type: c.GET_BANNER_MESSAGE
})

export const getBannerMessageSuccess = (payload) => ({
  type: c.GET_BANNER_MESSAGE_SUCCESS,
  payload: payload
})

export const getBannerMessageError = (error) => ({
  type: c.GET_BANNER_MESSAGE_ERROR,
  payload: error
})
